.home-blog {
	width: 100%;
	float: left;
	padding: 97px 0;
	position: relative;
	top: -40px;

	@media #{$tablet1} {
		padding: 82px 0 97px;
    }

    @media #{$tablet} {
        top: 0;
        margin-top: 0;
    }

	&:before {
		width: 300%;
		height: 100%;
		@include pseudo;
		top: 0;
		left: -100%;
		bottom: 0;
		background: #f7f7f7;
		z-index: -1;
		border-bottom: 1px solid #eaeaea;
	}

	>.small-title {
		position: absolute;
		top: 160px;
		left: 0;

		@media #{$desktop} {
			left: 20px;
	    }

	    @media #{$tablet1} {
			position: relative;
			text-align: center;
			top: 0;
			left: 0;
			display: block;
	    }

		h4 {
			font-size: 43px;
			font-weight: normal;
			letter-spacing: -0.03px;
		}

		a {
			font-size: 18px;
			letter-spacing: -0.2px;
			margin-top: 26px;
		}
	}

	.blog-container-hp {
		position: relative;
		padding: 0 0 0 270px;

		@media #{$tablet1} {
			padding: 50px 0 0 0;
			display: block;
	    }

		ul {
			width: 100%;
			float: left;
			@include border-box;
			border: 1px solid #eaeaea;

			@media #{$tablet1} {
				border-left: 0;
				border-right: 0;
		    }

			li {
				width: 50%;
				height: 400px;
				float: left;
				position: relative;
				background: $white;
				@include border-box;
				@include transition(all 0.24s ease);

				@media #{$tablet} {
			        width: 100%;
			    }

				&:nth-child(2) {
					border-left: 1px solid #eaeaea;

					@media #{$tablet} {
				        border-left: 0;
				        border-top: 1px solid #eaeaea;
				    }
				}

				&:hover {
					background: $pink;

					a {
						color: $white;

						>div {

							>span {
								color: #f79bc1;

								&.gnr-link {
									color: $white;

									&:before {
										background: $white;
										bottom: 3px;
									}
								}

								>span {
									color: #f79bc1;

									&:before {
										background: #f79bc1;
									}
								}
							}
						}
					}
				}

				>a {
					@include absolute;
					
					color: $black;
					text-decoration: none;
					@include transition(all 0.24s ease);

					>div {
						display: block;
						position: absolute;
						top: 70px;
						left: 70px;
						right: 70px;
						bottom: 70px;

						>span {
							display: block;
							font-size: 12px;
							color: #888888;
							@include transition(all 0.24s ease);
							cursor: default;

							&:hover {

								& ~ .gnr-link {

									&:before {
										bottom: 0;
									}
								}
							}

							&.gnr-link {
								display: inline-block;
								position: absolute;
								left: 0;
								bottom: 0;
							    padding-bottom: 6px;
							    font-family: 'PT Serif', serif;
							    font-size: 16px;
							    color: #eb1d71;
							    cursor: pointer;

								&:before {
								    width: 100%;
								    height: 1px;
								    content: "";
								    position: absolute;
								    bottom: 0;
								    left: 0;
								    background: rgba(235,29,113,0.853);
								    @include transition(all 0.24s ease);
								}
							}

							>span {
								color: $pink;
								position: relative;
								padding: 4px 0;
								@include transition(all 0.24s ease);
								cursor: pointer;

								&:before {
								    width: 100%;
								    height: 1px;
								    content: "";
								    position: absolute;
								    bottom: 0;
								    left: 0;
								    background: rgba(235,29,113,.453);
								    @include transition(all 0.24s ease);
								}

								&:hover {
									color: $white;

									&:before {
										bottom: 3px;
										background: $white;
									}
								}
							}
						}

						h4 {
							margin-top: 22px;
							display: -webkit-box;
							font-size: 30px;
							line-height: 40px;
							font-weight: normal;
							letter-spacing: -0.25px;
							-webkit-line-clamp: 3;
						    max-height: 118px;
						    text-overflow: ellipsis;
						    overflow: hidden;
						    -webkit-box-orient: vertical;
						}
					}
				}
			}
		}
	}
}