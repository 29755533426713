







.lazyload,
.lazyloading {
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    transition: opacity 0.3s;
}
.lazyloaded {
    opacity: 1 !important;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
img.img-center {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%,0);
    -moz-transform: translate(-50%,0);
    -ms-transform: translate(-50%,0);
    -o-transform: translate(-50%,0);
    transform: translate(-50%,0);
    z-index: 1;
}
img.img-center2 {
    height: 100%;
    position: relative;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%,0);
    -moz-transform: translate(-50%,0);
    -ms-transform: translate(-50%,0);
    -o-transform: translate(-50%,0);
    transform: translate(-50%,0);
    z-index: 1;
}
img.blur {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(-50%,0);
    -moz-transform: translate(-50%,0);
    -ms-transform: translate(-50%,0);
    -o-transform: translate(-50%,0);
    transform: translate(-50%,0);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    -webkit-transform: scale(2,2);
    -moz-transform: scale(2,2);
    -o-transform: scale(2,2);
    -ms-transform: scale(2,2);
    transform: scale(2,2);
    z-index: -1;
}
img.lazyloaded ~ img.blur { display: none !important; }

section#home-catalog .large-grid,
section#main .large-grid,
footer .large-grid { padding: 0; }
.large-grid {
    max-width: 1170px;
    position: relative;
    padding: 0 20px;
    margin: 0 auto;
    z-index: 2;
}
.large-grid.medium {
    max-width: 970px;
    position: relative;
    padding: 0 20px;
    margin: 0 auto;
}

.mask {
    width: 100%;
    float: left;
    position: relative;
}
.width-section {
    width: 100%;
    float: left;
    position: relative;
    visibility: hidden;
}

a.gnr-link {
    display: inline-block;
    position: relative;
    padding-bottom: 6px;
    font-family: 'PT Serif', serif;
    font-size: 16px;
    color: #eb1d71;
    text-decoration: none;
}
a.gnr-link:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(235,29,113,0.853);
}
.three-sections {
    width: 33.3333%;
    width: calc(100% / 3);
    float: left;
    position: relative;
    overflow: hidden;
}

h1 {
    font-size: 80px;
    font-weight: normal;
    line-height: 90px;
    letter-spacing: -0.04em;
}
h1:after {
    display: inline;
    content: ".";
    color: #eb1d71;
}
img.preload-zoom {
    -webkit-transition: 0.24s ease-out;
    -moz-transition: 0.24s ease-out;
    -ms-transition: 0.24s ease-out;
    -o-transition: 0.24s ease-out;
    transition: 0.24s ease-out;
}

.large-grid.medium.medium2 {
    max-width: 1110px;
}
h1.hack1 { margin-left: 100px; }



@media screen and (max-width: 660px) {
    .search-icon a {
        width: 60px;
        height: 60px;
        top: 22px;
        background: url("../images/icon_search.png") no-repeat 50%;
    background: url("../images/icon_search.svg") no-repeat 50%, linear-gradient(transparent, transparent);
    }
    .search-icon a > span {
        display: none;
    }

    .search-icon a:before,
    .search-icon a:after {
    top: 32px;
}
}
.search-icon a:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.search-icon a:after {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
.search-icon a.close-search:before {
    opacity: 1;
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
.search-icon a.close-search:after {
    opacity: 1;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#the-basics {
    display: none;
    position: absolute;
    top: 0;
    left: 136px;
    right: 136px;

    @media #{$tablet} {
        left: 10px;
        right: 10px;
        top: 50px;
    }

    @media #{$mobil6} {
        left: 0;
        right: 0;
    }
}

#the-basics input {
    width: 100%;
    padding: 8px 10px 5px;
    display:block;
    position: relative;
    font-family: "PT Serif";
    font-size: 20px;
    letter-spacing: -0.025em;
    color: #888;
    border:none;
    background: transparent;
    z-index: 3;
}

.grp1 input:focus { color: #21252b !important }
.grp1 input::-webkit-input-placeholder {
   color: #888;
   opacity: 1;
}

.grp1 input:-moz-placeholder {
   color: #888;
   opacity: 1;
}

.grp1 input::-moz-placeholder {
   color: #888;
   opacity: 1;
}

.grp1 input:-ms-input-placeholder {  
   color: #888;
   opacity: 1;
}
.grp1 {
    height: 60px;
    text-align: left;
    position: relative;
    font-size: 0;
    margin-top: 32px;
    -webkit-transition: all 0.12s;
    -moz-transition: all 0.12s;
    -o-transition: all 0.12s;
    -ms-transition: all 0.12s;
    transition: all 0.12s;
    border-bottom: 2px solid #eb1d71;
}
@-moz-document url-prefix() { 
  .grp1 {
    margin-top: 33px;
  }
}

/*****************************************************************
colorbox
*/
#colorbox, #cboxOverlay, #cboxWrapper { position:absolute; top:0; left:0; z-index:9999;  }
#cboxOverlay { position:fixed; width:100%; height:100%; }
#cboxMiddleLeft, #cboxBottomLeft { clear:left; }
#cboxContent { position:relative; }
#cboxLoadedContent { overflow:auto; -webkit-overflow-scrolling: touch; }
#cboxLoadingOverlay, #cboxLoadingGraphic { background:#fff; position:absolute; top:0; left:0; width:100%; height:100%; }
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow { cursor:pointer; }
.cboxPhoto { float:left; margin:auto; border:0; display:block; max-width:none; }
.cboxIframe { width:100%; height:100%; display:block; border:0; }
#colorbox, #cboxContent, #cboxLoadedContent { box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box; }
#cboxOverlay { background:#fff; }
#colorbox { outline:0; }
#cboxContent { background:#fff; font-family: "Helvetica", sans-serif; }
.cboxIframe { background:#fff; }
#cboxError { padding:50px; border:none; }
#cboxLoadedContent { border:none; background:#fff; }
#cboxTitle { position:absolute; bottom: -24px; left:80px; color:#111;background: #fff; }
#cboxCurrent {
    width: 66px;
    height: 66px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -35px;
    line-height: 68px;
    text-align: center;
    color: #4a4c50;
    font-size: 14px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    z-index: 99;
    border: 2px solid #eb1d71;
    background: #fff;
    -webkit-box-shadow: 0 4px 14px rgba(0,0,0,0.25);
    -moz-box-shadow: 0 4px 14px rgba(0,0,0,0.25);
    box-shadow: 0 4px 14px rgba(0,0,0,0.25);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
#cboxLoadingGraphic {
}
#cboxLoadingGraphic .loading-next-projects {
    position: absolute;
    top: 50%;
    margin: -35px 0 0 -35px;
}
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose { border:0; padding:0; margin:0; overflow:visible; width:auto; background:none; }
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active { outline:0; }
#cboxSlideshow { position:absolute; top:-20px; right:90px; color:#fff; }
#cboxClose {
    width: 60px;
    height: 60px;
    outline: none;
    text-indent:-9999px;
    background: no-repeat 50%;
    z-index: 20;
}
#cboxPrevious, #cboxNext {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 10;
    outline: none;
    text-indent:-9999px;
    background: no-repeat 50%;
}
#cboxPrevious {
    left: 0;
}
#cboxNext {
    right: 0;
}
body.touch #cboxPrevious {
    width: 60px;
    height: 60px;
    top: 50%;
    left: 6px;
    margin-top: -30px;
    background: url("../images/cursor-prev.png") no-repeat 50%;
    background: url("../images/cursor-prev.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}
body.touch #cboxNext {
    width: 60px;
    height: 60px;
    right: 6px;
    top: 50%;
    margin-top: -30px;
    background: url("../images/cursor-next.png") no-repeat 50%;
    background: url("../images/cursor-next.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}
#cboxClose {
    position: absolute;
    top: 92px;
    right: 83px;
    background: url("../images/icon-close.png") no-repeat 50%;
    background: url("../images/icon-close.svg") no-repeat 50%, linear-gradient(transparent, transparent);
    background-size: 50%;
}
body.touch #cboxClose {
    position: absolute;
    top: 36px;
    right: 23px;
}
.video-wrapper {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
}
.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.scroll-fix {
    position: relative;
    float: left;
    top: 0;
    left: 0
}
body.stop { overflow-y: hidden; }
#root {
    width: 100%;
    height: 100%;
    float: left;
    position: relative;
    overflow: hidden;
    z-index: 1;
}


/* home */
section#mesh-animation {
    width: 100%;
    min-height: 420px;
    display: table;
    position: relative;
    float: left;
    background-repeat: no-repeat;
    background-position: 50% 0;
    @include cover;
    overflow: hidden;

    &:before {
        width: 100%;
        height: 100%;
        @include pseudo;
        top: 0;
        left: 0;
        z-index: 1;
        background: rgba(0,0,0,0.1);
    }
}
section#mesh-animation .large-grid {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 20;
}
section#mesh-animation .large-grid .mask {
    max-width: 100%;
    display: block;
    float: none;
    margin: 0 auto;
}
#video-trns {
    width: 100%;
    position: relative;
    display: table;

    &:before {
        width: 100%;
        height: 590px;
        @include pseudo;
        top: 50%;
        left: 0;
        z-index: -1;
        transform: translate(0, -50%);
        background: url(../images/title.png) no-repeat 50%;
    }
}
#video-trns .apex-title {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 5;
    text-align: center;
}
#video-trns .apex-title  h1 {
    max-width: 500px;
    margin: 0 auto;
    color: $white;
    text-shadow: 0 20px 40px rgba(0,0,0,0.2);

    @media #{$tablet1} {
        max-width: 350px;
    }
}

.loading-next-projects {
    width: 70px;
    height: 70px;
    display: block;
    float: left;
    left: 50%;
    top: -5px;
    margin: 0 auto 0 -35px;
    border-radius: 50%;
    background: #eb1d71;
    position: absolute;
    pointer-events: none;

    &.small {
        width: 30px;
        height: 30px;
        top: 0;
        margin: 0 auto 0 -15px;

        .loader {
            width: 14px;
            height: 14px;
            margin: -9px 0 0 -9px;
        }

        &.hide {
            display: none;
        }
    }
}
.loader {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -14px 0 0 -14px;
    font-size: 0;
    text-indent: -9999em;
    border-top: 2px solid #fff;
    border-right: 2px solid rgba(255, 255, 255, 0);
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    border-left: 2px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load 0.7s infinite linear;
    -moz-animation: load 0.7s infinite linear;
    -ms-animation: load 0.7s infinite linear;
    animation: load 0.7s infinite linear;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
.loader:after {
    width: 24px;
    height: 24px;
    content: "";
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

section#ajax-projects {
    width: 100%;
    position: relative;
    float: left;
    z-index: 3;
}
section#ajax-projects.all-projects {
    padding-bottom: 120px;

    @media #{$tablet2} {
        padding-bottom: 100px;
    }

    @media #{$mobil2} {
        padding-bottom: 100px;
    }
}

ul#project-list {
    width: 100%;
    float: left;
    position: relative;
}
ul#project-list:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: #eaeaea;
}
ul#project-list li {
    width: 25%;
    width: calc(100% / 4);
    float: left;
    position: relative;
    overflow: hidden;
    @include border-box;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
}
ul#project-list li.category {
    background: #eb1d6f;
}

.content .category-title { width: 100%; height: 100%; display: table;vertical-align: middle; }
.content .category-title span {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-family: 'PT Serif', serif;
    font-size: 40px;
    font-weight: normal;
    line-height: 50px;
    letter-spacing: -0.025em;
    color: #fff;
}
ul#project-list li.category + li a.large-link:before {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -20px;
    border-style: solid;
    border-width: 20px 0 20px 15px;
    border-color: transparent transparent transparent #eb1d6f;
    z-index: 1;
}
ul#project-list li > span.double-touch {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;
}
body.touch ul#project-list li.visible > span.double-touch { display: none; }
body.touch ul#project-list li > span.double-touch { display: block; }
ul#project-list li a.large-link {
    display: block;
    position: relative;
    color: #fff;
    text-decoration: none;
}
ul#project-list li a.large-link .title {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(235,29,113,0.80);
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: opacity 0.24s ease;
    -moz-transition: opacity 0.24s ease;
    -o-transition: opacity 0.24s ease;
    -ms-transition: opacity 0.24s ease;
    transition: opacity 0.24s ease;
}
ul#project-list li.visible a.large-link .title,
ul#project-list li.visible a.small-link,
ul#project-list li:hover a.large-link .title,
ul#project-list li:hover a.small-link {
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
ul#project-list li.visible a.large-link .title h2 { top: 0; }

ul#project-list li a.large-link .title h2 {
    position: relative;
    padding: 110px 68px 0;
    top: -10px;
    font-family: 'PT Serif', serif;
    font-size: 30px;
    font-weight: normal;
    line-height: 40px;
    letter-spacing: -0.025em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 118px;
    text-overflow: ellipsis;
    color: #21252b;
}
section#ajax-projects.all-projects.page ul#project-list li a.large-link .title h2 {
    padding: 68px 68px 0;
}
body.touch ul#project-list li a.large-link .title h2,
ul#project-list li:hover a.large-link .title h2 {
    top: 0;
}
.row {
    width: 100%;
    display: inline-block;
    position: relative;
    vertical-align: top;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
}
.row:before {
    content: "";
    display: block;
    padding-top: 100%;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
}
.row .content {
    display: block;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
}
ul#project-list li a.large-link .row .content img {
    width: 100%;
    min-width: 100%;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
}
ul#project-list li a.small-link {
    padding: 10px;
    position: absolute;
    top: 70px;
    left: 58px;
    z-index: 11;
    font-size: 12px;
    color: rgba(255,255,255,0.68);
    text-decoration: none;
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: opacity 0.24s ease;
    -moz-transition: opacity 0.24s ease;
    -o-transition: opacity 0.24s ease;
    -ms-transition: opacity 0.24s ease;
    transition: opacity 0.24s ease;
}
ul#project-list li a.small-link span { position: relative; }
ul#project-list li a.small-link span:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    background: rgba(255,255,255,0.4);
}
ul#project-list li:hover a.small-link { top: 60px; }
body.touch ul#project-list li.visible a.small-link { top: 60px; }
ul#project-list li a.project-banner {
    display: block;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    color: #21252b;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
}
ul#project-list li a.project-banner .row .content:before,
ul#project-list li a.project-banner .row .content:after {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    left: 0;
    background: #eaeaea;
}
ul#project-list li a.project-banner .row .content:before { top: 0; }
ul#project-list li a.project-banner .row .content:after { bottom: 0; }
_::-webkit-:not(:root:root), ul#project-list li a.project-banner .row .content:after {
    bottom: 0;
}
.date {
    display: block;
    position: absolute;
    top: 70px;
    left: 68px;
    font-size: 12px;
    color: #888888;
    z-index: 10;
}
.date.detail {
    position: relative;
    top: 0;
    left: 0;
}
.date a {
    display: inline-block;
    position: relative;
    padding-bottom: 3px;
    color: #eb1d71;
    text-decoration: none;
}
.date a:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(235,29,113,0.453);
}
ul#project-list li a.project-banner .row .content h2 {
    position: relative;
    padding: 110px 68px 0;
    font-family: 'PT Serif', serif;
    font-size: 30px;
    font-weight: normal;
    line-height: 40px;
    letter-spacing: -0.025em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 118px;
    text-overflow: ellipsis;
    color: #21252b;
}
section#ajax-projects.all-projects.page ul#project-list li a.project-banner .row .content h2 {
    padding: 68px 68px 0;
}
ul#project-list li a.project-banner .row .content span.medium-link {
    display: inline-block;
    padding-bottom: 6px;
    position: absolute;
    bottom: 70px;
    left: 68px;
    font-family: 'PT Serif', serif;
    font-size: 16px;
    color: #eb1d71;
    text-decoration: none;
}
ul#project-list li a.project-banner .row .content span.medium-link:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(235,29,113,0.853);
}







section#main {
    width: 100%;
    float: left;
    position: relative;
    overflow: hidden;

    & + footer #general-footer {
        border-top: 0;
    }
}
#home-sct.mask {
    padding: 120px 0 0 0;
}
#home-sct .title,
#home-sct .text {
    width: 50%;
    float: left;
}
#home-sct .title h3 {
    max-width: 420px;
    position: relative;
    padding: 0 0 0 100px;
    font-family: 'PT Serif', serif;
    font-size: 60px;
    font-weight: normal;
    line-height: 70px;
    letter-spacing: -0.04em;
}
#home-sct .text > p {
    position: relative;
    padding: 0 100px;
    font-size: 16px;
    font-weight: normal;
    line-height: 30px;
    color: #888888;
}
#home-sct .text > p:nth-child(2) { margin-top: 30px;  }
#home-sct .text > a {
    margin: 20px 0 0 100px;
    font-size: 18px;
}
#home-sct .img-box {
    width: 50%;
    width: calc(50% - 15px);
    display: block;
    position: relative;
    overflow: hidden;
}

.width-section.img-section {
    padding-bottom: 130px;
}

#home-sct .img-box.img1 {
    top: -40px;
}
#home-sct .img-box.img2 {
    top: -10px;
}
#home-sct .img-box.img3 {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -110px;
    -webkit-transform: translate(0,-50%);
    transform: translate(0,-50%);
}


#home-sct .img-box img {
    width: 100%;
}

#home-sct .img-box .container-img ul,
#home-sct .img-box .container-img ul li { display: block; }












#home-sct .title.ttl2 { width: 100%; }
#home-sct .title .small-nav {
    width: 400px;
    display: block;
    margin-left: 100px;
}
#home-sct .title .small-nav span {
    display: block;
    margin: 95px 0 20px 0;
    font-family: 'PT Serif', serif;
    font-size: 30px;
    font-weight: normal;
    letter-spacing: -0.025em;
}
#home-sct .title .small-nav li { width: 45%; float: left; }
#home-sct .title .small-nav a {
    margin: 12px 0;
}
#home-sct .title .small-nav li:nth-child(even) { width: 55% }
#home-sct .text .small-slider {
    display: block;
    position: relative;
    top: 0;
    margin: 0 0 0 100px;
}
#home-sct .text .small-slider span {
    display: block;
    margin: 0;
    font-family: 'PT Serif', serif;
    font-size: 30px;
    font-weight: normal;
    letter-spacing: -0.025em;
}
#home-sct .img-box.img1 .container-img.cnt1 { margin-top: 120px; }
#home-sct .text .small-slider .bx-controls.bx-has-pager { display: table;margin: 30px auto 0;float: left; }
#home-sct .text .small-slider .bx-pager-item {
    float: left;
    margin: 0 1px;
}
#home-sct .text .small-slider .bx-pager-item a {
    width: 40px;
    height: 40px;
    display: block;
    position: relative;
    color: #eb1d71;
    font-size: 12px;
    line-height: 40px;
    text-decoration: none;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

#home-sct .text .small-slider .bx-pager-item a:before {
    content: "";
    position: absolute;
    top: 0;left: 0;
    right: 0;
    bottom: 0;opacity: 0;
    border: 1px solid #dedede;
    -webkit-border-radius: 50%;-moz-border-radius: 50%;border-radius: 50%;@include transition(all 0.24s ease); }
    #home-sct .text .small-slider .bx-pager-item a:hover:before {opacity: 1;}

#home-sct .text .small-slider .bx-pager-item a.active {
    background: #eb1d71;
    color: #fff;
    font-weight: 700;
}
#home-sct .text .small-slider .bx-pager-item a.active:before {
    display: none;
}

#home-sct .text .small-slider .slide-title {
    height: 70px;
    display: block;
    position: relative;
    margin-top: 40px;
    padding-left: 100px;
    font-family: 'PT Serif', serif;
    font-size: 22px;
    font-weight: normal;
    line-height: 70px;
}
#home-sct .text .small-slider ul { margin: 0;display: block; top: -9px; }
#home-sct .text .small-slider ul li .slide-title:before {
    width: 70px;
    height: 70px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
#home-sct .text .small-slider ul li .slide-title:after {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    color: #fff;
    z-index: 2;
}
#home-sct .text .small-slider ul li.icon1 .slide-title:before {
    background: #eb1d71;
}
#home-sct .text .small-slider ul li.icon1 .slide-title:after {
    content: "1";
}
#home-sct .text .small-slider ul li.icon2 .slide-title:before {
    background: #eb1d71;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
#home-sct .text .small-slider ul li.icon2 .slide-title:after {
    content: "2";
}
#home-sct .text .small-slider ul li.icon3 .slide-title:before {
    width: 0;
    height: 0;
    text-align: left;
    border-style: solid;
    border-width: 0 40px 70px 40px;
    border-color: transparent transparent #eb1d71 transparent;
}
#home-sct .text .small-slider ul li.icon3 .slide-title:after {
    content: "3";
    left: 5px;
    top: 8px;
}
#home-sct .text .small-slider ul li.icon4 .slide-title:before {
    left: 13px;
    background: #eb1d71;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    transform: skew(-20deg);
}
#home-sct .text .small-slider ul li.icon4 .slide-title:after {
    content: "4";
    left: 12px;
}
#home-sct .text .small-slider ul li p {
    position: relative;
    margin-top: 20px;
    padding-right: 100px;
    font-size: 16px;
    font-weight: normal;
    line-height: 30px;
    color: #888888;
}

.slider-links {
    width: 100%;
    float: left;
    margin-top: 36px;

    @media #{$tablet} {
        text-align: center;
    }

    a {
        font-size: 18px;
        line-height: -0.15px;
    }
}





section#header-title {
    width: 100%;
    position: relative;
    float: left;
    padding: 340px 0 130px;

    &.projects {
        z-index: 12;
        padding: 340px 0 0;
    }
}
section#header-title p {
    margin-top: 35px;
    text-align: left;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: -0.020em;
    color: #888;
}
section#header-title p strong { font-weight: bold; color: #21252b; }
section#header-title p a {
    display: inline-block;
    position: relative;
    padding-bottom: 10px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 1;
    color: #eb1d71;
    text-decoration: none;
}
section#header-title p a:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(235,29,113,0.853);
}
section#header-title.medium {
    padding: 240px 0 123px;
}
section#header-title.medium h1 {
    text-align: center;
}
section#header-title.medium p {
    margin-top: 35px;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: -0.020em;
    color: #888;
}
section#header-title.error { padding-bottom: 180px; }


#project-filter {
    width: 100%;
    min-height: 98px;
    float: left;
    position: relative;
    margin-top: 65px;
    text-align: center;
    font-size: 0;

    @media #{$tablet} {
        // min-height: 150px;
    }

    .cnt-slb {
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin-left: 39px;

        @media #{$desktop3} {
            display: block;
            margin: 0;
            padding: 0 110px 0 180px;
        }

        @media #{$tablet} {
            margin-top: 20px;
            padding: 0 110px 0 0;
        }

        @media #{$mobil2} {
            margin-top: 40px;
            padding: 0 0;
        }
    }
}
#project-filter .select-box {
    width: 200px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-right: 20px;
    text-align: left;

    @media #{$desktop3} {
        width: 30%;
        width: calc(100% / 3 - 20px);
    }

    @media #{$mobil2} {
        display: block;
        width: 100%;
    }
}
.select-box.sml1 {

}

.search-icon {
    width: 136px;
    height: 96px;
    position: relative;
    margin-top: 27px;
    float: left;
    z-index: 10;

    @media #{$desktop3} {
        position: absolute;
        top: 0;
        left: 30px;
    }

    @media #{$tablet} {
        display: inline-block;
        vertical-align: top;
        float: none;
        position: relative;
        left: 0;
        margin-top: 0;
    }

    @media #{$mobil2} {
        width: 60px;
        height: 60px;
    }
}
.search-icon a {
    width: 100%;
    height: 75px;
    float: left;
    @include transition(all 0.22s ease);

    &:hover {
        span.icon {
            background-color: $black;
        }
    }

    span.icon {
        width: 60px;
        height: 60px;
        display: block;
        float: left;
        margin: 0 auto;
        @include border-radius;
        background: $pink url("../images/icon_search.svg") no-repeat 49%;
    }

    span {
        @include transition(all 0.22s ease);
    }
}

.search-icon a > span.title {
    float: left;
    margin: 15px 0 0 20px;
    font-size: 12px;
    text-transform: uppercase;
    text-align: left;
    color: #21252b;
    line-height: 16px;
}
.search-icon a.open {
    span {
        opacity: 0;
    }
}

.search-icon a:before,
.search-icon a:after {
    width: 25px;
    height: 2px;
    content: "";
    position: absolute;
    top: 27px;
    left: 50%;
    margin-left: -12px;
    -webkit-transition: all 0.18s ease;
    -moz-transition: all 0.18s ease;
    -o-transition: all 0.18s ease;
    -ms-transition: all 0.18s ease;
    transition: all 0.18s ease;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background: #eb1d71;
    opacity: 0;
}

.filter-form {
    display: inline-block;
    vertical-align: top;
    position: relative;
    transition: all 0.18s;

    &.hide-form {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    @media #{$desktop3} {
        width: 100%;
        display: block;
    }
}
#project-filter .select-box .small-title {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 0;
}
#project-filter .select-box .value-box {
    margin-top: 14px;
}

.value-box {
    position:relative;
    padding: 28px 0 24px;
    clear:both;
    border-bottom: 2px solid #eb1d71;
}
.value-box:before {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #eb1d6f transparent transparent transparent;
}
.value-box select {
    width: 100%!important;
    border: 0;
    font-size: 14px !important;
    background: transparent;
    *background: #58B14C;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: 0;
    text-shadow: 0;
    text-align: left;
}
.value-box select:focus {
    outline: 0;
    border: 0;
}
.value-box select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #888;
}
span.customSelect { 
    font-family: "PT Serif";
    font-size: 20px;
    color:#888;
}



#project-filter .small-select-box {
    width: 120px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: left;

    @media #{$desktop3} {
        position: absolute;
        top: 0;
        right: 0;
    }

    @media #{$mobil2} {
        position: relative;
        display: table;
        margin: 40px auto 0;
    }
}
#project-filter .small-select-box.ssb1 {
    margin-left: 28px;
}
#project-filter .small-select-box.ssb2 {

}
#project-filter .small-select-box .small-title {
    display: block;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
}     
#project-filter .small-select-box a {
    width: 26px;
    height: 26px;
    display: block;
    position: relative;
    margin: 26px auto 0;
    border: 2px solid #ccc;
    -webkit-transition: all 0.14s linear;
    -moz-transition: all 0.14s linear;
    -o-transition: all 0.14s linear;
    -ms-transition: all 0.14s linear;
    transition: all 0.14s linear;
    text-align: left;
}
#project-filter .small-select-box a:before,
#project-filter .small-select-box a:after {
    width: 16px;
    height: 2px;
    content: "";
    position: absolute;
    top: 12px;
    left: 5px;
    background: #ccc;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.14s linear;
    -moz-transition: all 0.14s linear;
    -o-transition: all 0.14s linear;
    -ms-transition: all 0.14s linear;
    transition: all 0.14s linear;
}
#project-filter .small-select-box a:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}
#project-filter .small-select-box a:after{
    -webkit-transform: rotate(-48deg);
    -moz-transform: rotate(-48deg);
    transform: rotate(-48deg);
}
#project-filter .small-select-box a.active {
    border: 2px solid #eb1d6f;
}
#project-filter .small-select-box a.active:before {
    width: 7px;
    top: 14px;
    left: 6px;
    background: #eb1d6f;
    -webkit-transform: rotate(-140deg);
    -moz-transform: rotate(-140deg);
    transform: rotate(-140deg);
}
#project-filter .small-select-box a.active:after {
    width: 12px;
    left: 10px;
    background: #eb1d6f;
    -webkit-transform: rotate(-225deg);
    -moz-transform: rotate(-225deg);
    transform: rotate(-225deg);
}
.btns {
    display: inline-block;
    vertical-align: top;

    @media #{$desktop3} {
        display: table;
        margin: 30px auto 0;
    }
}
button.search {
    height: 60px;
    margin: 25px 20px 0;
    padding: 0 29px;
    display: inline-block;
    vertical-align: top;
    color: $white;
    background-color: $pink;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    text-transform: uppercase;
    @include transition( all 0.22s ease-out);

    @media #{$desktop3} {
        margin: 25px 20px 0 0;
    }

    &:hover {
        background-color: $black;
    }
}
button.reset {
    width: 60px;
    height: 60px;
    margin: 25px 0 0 0;
    display: inline-block;
    vertical-align: top;
    color: $white;
    position: relative;
    background-color: #cccccc;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    text-transform: uppercase;
    @include border-radius;
    @include transition( all 0.22s ease-out);

    &:hover {
        background-color: $black;

        span {
            @include rotate(360);
        }
    }

    span {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -20px 0 0 -20px;
        background: url(../images/icon-reset.svg) no-repeat 50%;
        -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    @include transition( all 0.7s ease);
    }
}
/* detail project */
section#detail-container {
    width: 100%;
    float: left;
    position: relative;
    padding-bottom: 30px;
    z-index: 99;
}
section#detail-container .large-grid { padding: 0; }
.large-slider {
    width: 100%;
    min-height: 72px;
    float: left;
    position: relative;
}
.large-slider .bx-controls {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}
body.touch .large-slider .bx-controls { display: none; }
.large-slider .bx-controls a {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
}
.large-slider .bx-controls a.bx-prev { left: 0; }
.large-slider .bx-controls a.bx-next { right: 0; }
.slide-counter {
    width: 66px;
    height: 66px;
    position: absolute;
    top: 50%;
    right: -35px;
    margin-top: -35px;
    line-height: 68px;
    text-align: center;
    color: #4a4c50;
    font-size: 14px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    z-index: 99;
    border: 2px solid #eb1d71;
    background: #fff;
    -webkit-box-shadow: 0 4px 14px rgba(0,0,0,0.25);
    -moz-box-shadow: 0 4px 14px rgba(0,0,0,0.25);
    box-shadow: 0 4px 14px rgba(0,0,0,0.25);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.slide-counter:before {
    width: 26px;
    height: 1px;
    content: "";
    position: absolute;
    top: 50%;
    left: 21px;
    background: #ccc;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(-58deg);
    -moz-transform: rotate(-58deg);
    transform: rotate(-58deg);
}
ul.detail-product-slider li {
    display: inline-block;
    overflow: hidden;
}
ul.detail-product-slider li img {
    width: 100%;
    position: relative;
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -o-transform: scale(1,1);
    -ms-transform: scale(1,1);
    transform: scale(1,1);
    -webkit-transition: 59s ease-out;
    -moz-transition: 59s ease-out;
    -ms-transition: 59s ease-out;
    -o-transition: 59s ease-out;
    transition: 59s ease-out;
}
ul.detail-product-slider li.active-slide img {
    -webkit-transform: scale(1.08,1.08);
    -moz-transform: scale(1.08,1.08);
    -o-transform: scale(1.08,1.08);
    -ms-transform: scale(1.08,1.08);
    transform: scale(1.08,1.08);
    -webkit-transition: 5.2s ease-out;
    -moz-transition: 5.2s ease-out;
    -ms-transition: 5.2s ease-out;
    -o-transition: 5.2s ease-out;
    transition: 5.2s ease-out;
}
section#project-sketch {
    width: 100%;
    float: left;
    position: relative;
    padding-bottom: 100px;
    overflow: hidden;
}
section#project-sketch .mask { position: relative; }

#large-sketch {
    position: relative;
    margin-top: 140px;
    padding: 0 600px 0 0;
}
#large-sketch .container-large-img {
    width: 100%;
    height: 370px;
    display: block;
    margin: 0;
    position: relative;
}
#large-sketch .container-large-img a {
    width: 100%;
    height: 370px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
}
#large-sketch .container-large-img img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transition: 0.24s ease-out;
    -moz-transition: 0.24s ease-out;
    -ms-transition: 0.24s ease-out;
    -o-transition: 0.24s ease-out;
    transition: 0.24s ease-out;
}

#small-sketch {
    width: 100%;
    min-height: 65px;
    position: absolute;
    top: -40px;
    left: 0;
    z-index: 10;
    overflow: hidden;
}
ul#sketch-slider {
    width: auto;
    display: table;
    position: relative;
    margin: 0 auto;
    text-align: center;
    font-size: 0;
}
ul#sketch-slider li {
    width: auto;
    display: table-cell;
    vertical-align: bottom;
    margin: 0 auto;
    padding: 0 14px;
    overflow: hidden;
    text-align: center;
}
ul#sketch-slider li a {
    width: auto;
    max-width: 100%;
    display: inline-block;
    margin: 0 auto;
    position: relative;
    vertical-align: bottom;
    overflow: hidden;
}
ul#sketch-slider li a img {
    width: 100%;
    max-width: 260px;
    position: relative;
}
ul#sketch-slider li a:before,
#large-sketch .container-large-img a:before {
    width: 40px;
    height: 40px;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    z-index: 10;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    -moz-transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    -ms-transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    -o-transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    -webkit-transform: scale3d(.1,.1,.1);
    -moz-transform: scale3d(.1,.1,.1);
    -ms-transform: scale3d(.1,.1,.1);
    -o-transform: scale3d(.1,.1,.1);
    transform: scale3d(.1,.1,.1);
    opacity: 0;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    background: #eb1d71;
}
#large-sketch .container-large-img a:before {
    width: 70px;
    height: 70px;
    margin: -35px 0 0 -35px;
}
ul#sketch-slider li a:after,
#large-sketch .container-large-img a:after {
    width: 16px;
    height: 16px;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    z-index: 11;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    background: url("../images/icon-zoom.png") no-repeat 50%;
    background: url("../images/icon-zoom.svg") no-repeat 50%, linear-gradient(transparent, transparent);
    background-size: 16px;
    opacity: 0;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
}
#large-sketch .container-large-img a:after {
    width: 20px;
    height: 20px;
    margin: -10px 0 0 -10px;
    background-size: 20px;
}

#small-sketch .bx-controls {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -20px;
    z-index: 10;
}
#small-sketch .bx-controls a {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
}
#small-sketch .bx-controls a.bx-next {
    right: 0;
    background: url("../images/cursor-next.png") no-repeat 100% 50%;
    background: url("../images/cursor-next.svg") no-repeat 100% 50%, linear-gradient(transparent, transparent);
}
#small-sketch .bx-controls a.bx-prev {
    left: 0;
    background: url("../images/cursor-prev.png") no-repeat 0 50%;
    background: url("../images/cursor-prev.svg") no-repeat 0 50%, linear-gradient(transparent, transparent);
}

#project-table {
    width: 100%;
    max-width: 562px;
    height: 362px;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 140px;
    z-index: 10;
    border: 4px solid #eb1d71;
}
.view-add { margin-top: 80px; }
#project-table .row1 {
    width: 100%;
    height: 266px;
    float: left;
    border-bottom: 1px solid #eb1d71;
}
#project-table .row1 .column1 {
    width: 196px;
    height: 100%;
    float: left;
    border-right: 1px solid #eb1d71;
}
#project-table .row1 .column1 .box {
    width: 100%;
    height: 50%;
    display: table;
    position: relative;
}
#project-table .row1 .column1 .box:first-child:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: -0.5px;
    left: 0;
    background: #eb1d71;
}
#project-table .row1 .column1 .box .container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
#project-table .row1 .column1 .box .container span { display: block;line-height: 1; }
#project-table .row1 .column1 .box .container span:first-child {
    margin-bottom: 14px;
    color: #888;
    font-size: 12px;
    text-transform: uppercase;
}
#project-table .row1 .column1 .box .container span:last-child {
    font-family: "PT Serif";
    font-size: 30px;
    letter-spacing: -0.025em;
}
#project-table .row1 .column1 .box .container span:last-child sup { font-size: 18px; }
#project-table .row1 .column2 {
    width: 365px;
    height: 100%;
    float: left;
}
#project-table .row1 .column2 .box {
    width: 100%;
    height: 100%;
    display: table;
    position: relative;
}
#project-table .row1 .column2 .box .container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 0 40px;
}
#project-table .row1 .column2 .box .container .column-section {
    width: 100%;
    display: block;
    position: relative;
    margin: 14px 0 7px;
    text-align: left;
    line-height: 1;
}
#project-table .row1 .column2 .box .container .column-section span { display: inline-block;vertical-align: middle; line-height: 1;font-size: 12px; }
#project-table .row1 .column2 .box .container .column-section span:first-child {
    padding-right: 50px;
    color: #888;
    text-transform: uppercase;
}
#project-table .row1 .column2 .box .container .column-section span:last-child {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 1.4;
}
#project-table .row1 .column2 .box .container .column-section span:last-child sup {
    font-size: 8px;
}
#project-table .row2 {
    width: 100%;
    height: 95px;
    float: left;
}
#project-table .row2 .column {
    width: 50%;
    height: 100%;
    float: left;
    position: relative;
}
#project-table .row2 .column:first-child:before {
    width: 1px;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    right: -0.5px;
    background: #eb1d71;
}
#project-table .row2 .column .box {
    width: 100%;
    height: 100%;
    display: table;
    position: relative;
}
#project-table .row2 .column .box .container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
#project-table .row2 .column .box .container span { display: block;line-height: 1; }
#project-table .row2 .column .box .container span:first-child {
    margin-bottom: 12px;
    color: #888;
    font-size: 12px;
    text-transform: uppercase;
}
#project-table .row2 .column .box .container span:last-child {
    font-family: "PT Serif";
    font-size: 20px;
    letter-spacing: -0.025em;
    color: #eb1d71;
}
#project-table .row2 .column .box .container span:last-child sup { font-size: 18px;position: relative;top: 4px; }

#about-table-project {
    width: 100%;
    max-width: 570px;
    float: right;
}
#about-table-project .table-price {
    display: block;
    margin-top: 20px;
    padding-left: 10px;
    color: #888;
    font-size: 12px;
    text-transform: uppercase;
    position: relative;
}
#about-table-project .table-price sup {
    color: #eb1d71;
    font-size: 15px;
    position: absolute;
    top: -1px;
    left: 0;
}
#about-table-project a {
    width: 150px;
    display: block;
    position: relative;
    margin-top: 30px;
    padding-bottom: 10px;
    color: #eb1d71;
    text-decoration: none;
    font-family: "PT Serif";
    font-size: 16px;
    line-height: 1;
}
#about-table-project a:before,
#interest a:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(235,29,113,0.853);
}
#interest {
    width: 100%;
    float: left;
    position: relative;
    margin-top: 130px;
    padding-top: 130px;
    border-top: 1px solid #eaeaea;
    text-align: center;
}
#interest.buy { margin-top: 80px; }
#interest .texts {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-family: "PT Serif";
    font-size: 30px;
    letter-spacing: -0.025em;
}
#interest .texts a {
    position: relative;
    padding-bottom: 5px;
    color: #eb1d71;
    text-decoration: none;
}

/* what have this projekt */
section#header-title.this-project { padding-bottom: 100px; }
section#two-columns {
    width: 100%;
    float: left;
    position: relative;
    overflow: hidden;
}
section#two-columns .grid-row {
    width: 100%;
    float: left;
    padding-bottom: 65px;
}
section#two-columns .grid-row .grid-box {
    width: 50%;
    float: left;
    position: relative;
    padding-bottom: 65px;
}
section#two-columns .grid-row .grid-box .container {
    min-height: 70px;
    position: relative;
    padding: 16px 0 0 100px;
}
section#two-columns .grid-row .grid-box .container:before {
    width: 66px;
    height: 66px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #eb1d71;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: url("../images/icon-ok.png") no-repeat 50%;
    background: url("../images/icon-ok.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}
section#two-columns .grid-row .grid-box .container h2 {
    display: block;
    font-weight: normal;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.025em;
}
section#two-columns .grid-row .grid-box .container p {
    position: relative;
    padding-right: 20px;
    margin-top: 26px;
    font-size: 16px;
    line-height: 30px;
    color: #888;
}
section#two-texts {
    width: 100%;
    float: left;
    position: relative;
    overflow: hidden;
    padding-bottom: 130px;
}
section#two-texts .large-grid { padding: 0; }
section#two-texts .large-grid .mask {
    padding: 130px 0;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
}
.text-container {
    width: 50%;
    float: left;
}
.text-container .box {
    position: relative;
    padding: 0 100px;
}
.text-container:nth-child(1) .box { padding: 0 120px 0 100px; }
.text-container:nth-child(2) .box{ padding: 0 100px 0 120px; }
.text-container .box p {
    position: relative;
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 30px;
    color: #888;
}
.text-container .box p.large {
    font-size: 20px;
    line-height: 40px;
    letter-spacing: -0.020em;
}
.text-container .box p strong {
    font-weight: bold;
    color: #21252b;
}
section#text-img .text-container .box p:last-child { margin-bottom: 0; }
section#text-img .text-container .box img {
    width: 100%;
    max-width: 100%;
    -webkit-transition: 0.24s ease-out;
    -moz-transition: 0.24s ease-out;
    -ms-transition: 0.24s ease-out;
    -o-transition: 0.24s ease-out;
    transition: 0.24s ease-out;
}
section#text-img .text-container:nth-child(2) .box { padding: 0; }
.text-container .box span {
    display: block;
    position: relative;
    margin-top: 10px;
    clear: both;
    padding-left: 50px;
    font-size: 16px;
    line-height: 30px;
    color: #888;
}
.text-container .box span:before {
    width: 25px;
    height: 1px;
    content: "";
    position: absolute;
    top: 14px;
    left: 0;
    background: #eb1d71;
}
section#text-img {
    width: 100%;
    float: left;
    position: relative;
    overflow: hidden;
    padding-bottom: 130px;
}
section#text-img .large-grid { padding: 0; }


/* about us */
section#about-us {
    width: 100%;
    float: left;
    position: relative;
    overflow: hidden;
    padding-bottom: 130px;
}

section#about-us .mask {position: relative;}
#banner-about-us {
    width: 570px;
    height: 400px;
    display: table;
    float: right;
    margin-top: 100px;
    z-index: 10;
    background: #eb1d71;
}
#banner-about-us article {
    display: table-cell;
    vertical-align: middle;
    position: relative;
}
#banner-about-us article p {
    padding: 0 100px;
    font-family: "PT Serif";
    font-size: 30px;
    line-height: 40px;
    color: #fff;
    letter-spacing: -0.025em;
}
#banner-about-us article p span {
    display: block;
    margin-top: 20px;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 700;
    opacity: 0.5;
    letter-spacing: 0;
}
.full-width {
    width: 100vw;
    height: 600px;
    display: block;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background: url("../images/about-large.jpg") no-repeat 50%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
section#about-us article.all-text {
    max-width: 970px;
    margin: 0 auto;
    padding: 95px 0 130px;
}
section#about-us article.all-text p { 
    margin: 35px auto 0;
    text-align: left;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: -0.020em;
    color: #888;
}
section#about-us article.all-text p strong {
    font-weight: bold;
    color: #21252b;
}
#we-are {
    width: 100%;
    width: calc(100% / 1 - (1 * 2px));
    height: 733px;
    float: left;
    position: relative;
    margin-top: 100px;
    overflow: hidden;
    border: 1px solid #eb1d71;
    visibility: hidden;
}
#we-are:before,
#we-are:after {
    width: 832px;
    height: 1081px;
    content: "";
    position: absolute;
    background: url("../images/about-profile1.png") no-repeat 50%;
}
#we-are:before {
    top: -550px;
    left: -217px;
}
#we-are:after {
    bottom: -394px;
    right: -460px;
}
#we-are .profile-box {
    width: 50%;
    height: 100%;
    position: relative;
    float: left;
    display: table;
    z-index: 10;
}
#we-are .profile-box:nth-child(1):before {
    width: 200%;
    height: 88px;
    content: "&";
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -140px;
    font-size: 100px;
    text-align: center;
    line-height: 1;
    font-weight: 700;
    z-index: -1;
}
#we-are .profile-box .container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
#we-are .profile-box .container .img-profile {
    width: 370px;
    height: 370px;
    display: block;
    margin: 0 auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
}
#we-are .profile-box:nth-child(1) .container .img-profile {
    background: url("../images/jurij-cernak.jpg") no-repeat 50%;
}
#we-are .profile-box:nth-child(2) .container .img-profile {
    background: url("../images/miroslav-zilinsky.jpg") no-repeat 50%;
}
#we-are .profile-box .container .name {
    width: 100%;
    display: block;
    padding-top: 65px;
    position: relative;
}
#we-are .profile-box .container .name h2 {
    font-size: 22px;
    line-height: 40px;
    font-weight: 400;
}
#we-are .profile-box .container .name h2 span {
    display: block;
    font-family: "Roboto";
    font-size: 14px;
    line-height: 24px;
    color: #888;
}
#we-are .profile-box .container .name a {
    display: inline-block;
    position: relative;
    padding: 30px 0 10px;
    margin: 0 auto;
    font-family: 'PT Serif', serif;
    font-size: 14px;
    line-height: 1;
    color: #eb1d71;
    text-decoration: none;
}
#we-are .profile-box .container .name a:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(235,29,113,0.853);
}

#home-sct.large-grid {
    
    .width-section {
        margin-top: 130px;
    }
}

.img-box-about {
    width: 50%;
    float: left;
    top: 100px;
    position: relative;

    .container-img {
        position: relative;
        top: 0;
        padding-right: 15px;
        overflow: hidden;

        img {
            width: 100%;
            max-width: 350px;
            margin: 0 auto;
        }

        ul {
            display: block;

            li {
                display: block;
            }
        }
    }
}


.all-text.animate-section {
    width: 100%;
    float: left;
    margin-top: 60px;
}

section#about-us img.about-large-img {
    width: 100%;
    max-width: 100%;
    float: left;
    margin-top: 100px;

}
section#about-us img {
    transition: opacity 0.24s ease;
}
section#about-us img.about-medium-img {
    width: 50%;
    width: calc(100% / 2 - (1 * 15px));
    float: left;
    margin-top: 30px;
}
section#about-us img.about-medium-img.img2 { float: right; }

#interest.no-border { border-top: 0;margin-top: 0; }
#interest.no-border .texts {
    font-size: 60px;
    line-height: 70px;
}
#interest.no-border:before { display: none; }


/* partners */
section#partners {
    width: 100%;
    float: left;
    position: relative;
    z-index: 20;
    margin: 55px 0 100px;
    border-top: 1px solid #eaeaea;
}
section#partners:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: #eaeaea;
    z-index: 2;
}

ul#partners-list {
    width: 100%;
    float: left;
}
ul#partners-list li {
    width: 25%;
    width: calc(100% / 4);
    float: left;
    position: relative;
    z-index: 21;
    visibility: hidden;
    border-bottom: 1px solid #eaeaea;
}
ul#partners-list li a {
    background: no-repeat 50%;
    background-size: 52%;
    border-right: 1px solid #eaeaea;
}
ul#partners-list li a span {
    width: 100%;
    height: 86px;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 12px;
    line-height: 20px;
    color: #888;
    text-decoration: none;
    text-align: center;
}
/* blog */
section#ajax-projects.all-projects.all-blog {
    margin-top: 85px;
    border-top: 1px solid #eaeaea;
}
ul.blog-filter {
    width: 100%;
    float: left;
    margin-top: 28px;
    font-size: 0;
}
ul.blog-filter li { display: inline-block; margin-right: 26px;margin-top: 20px; }
ul.blog-filter li a {
    display: inline-block;
    position: relative;
    padding: 2px 2px 10px;
    margin: 0 auto;
    font-family: 'PT Serif', serif;
    font-size: 20px;
    line-height: 1;
    color: #eb1d71;
    text-decoration: none;
    letter-spacing: -0.025em;
}
ul.blog-filter li a:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(235,29,113,0.853);
}
ul.blog-filter li a.active {
    color: #21252b;
}
ul.blog-filter li a.active:before { display: none; }
ul#project-list.blog li {
    border-bottom: 1px solid #eaeaea;
}
ul#project-list.blog li a.project-banner {
    border-right: 1px solid #eaeaea;
}
ul#project-list.blog li a.project-banner .row .content:before,
ul#project-list.blog li a.project-banner .row .content:after { display: none; }
/* detail blog */
#fb-large-button {
    position: fixed;
    top: 342px;
    left: 0;
    z-index: 1001;
    text-align: center;
    -webkit-transform: translateZ(0);
}
#fb-large-button .fb-container {
    display: inline-block;
    padding: 10px;
    position: relative;
    margin: 0 auto;
    z-index: 1001;
    background: #fff;
}
section#header-title.blog-post {
    padding: 300px 0 0px;
}
section#header-title.blog-post h1 {
    max-width: 900px;
    font-size: 60px;
    line-height: 70px;
    margin-top: 16px;
}
section#header-title.blog-post h1:after { display: none; }
section#two-columns.blog-post {
    padding: 0 0 100px;
}
section#two-columns .mask.detail-blog { position: relative; }
section#two-columns .mask.detail-blog .large-slider:first-child,
section#two-columns .mask.detail-blog article:nth-child(1) {
    margin-top: 100px!important;
}
section#two-columns.blog-post .large-grid.blog-post { padding: 0; }
section#two-columns.blog-post .mask.detail-blog .large-slider { float: none; }

section#two-columns .mask.detail-blog article,
section#two-columns .mask.detail-blog .large-slider {
    width: 100%;
    max-width: 1010px;
    position: relative;
    margin: 0 auto;
    margin-top: 130px;
    z-index: 20;
}
section#two-columns .mask.detail-blog article.text-section {
    margin-top: 118px;
    clear: both;
}
section#two-columns .mask.detail-blog .large-slider,
section#two-columns .mask.detail-blog article.img-section,
section#two-columns .mask.detail-blog article.small-img-section,
section#two-columns .mask.detail-blog article.video-section {
    max-width: 1170px;
}
section#two-columns .mask.detail-blog article.small-img-section { margin-top: 30px;font-size: 0; }
section#two-columns .mask.detail-blog article img {
    -webkit-transition: opacity 0.24s ease;
    -moz-transition: opacity 0.24s ease;
    -o-transition: opacity 0.24s ease;
    -ms-transition: opacity 0.24s ease;
    transition: opacity 0.24s ease;
}
section#two-columns .mask.detail-blog article.img-section img {
    width: 100%;
    max-width: 100%;
}
section#two-columns .mask.detail-blog article.small-img-section img {
    width: 50%;
    width: calc(100% / 2 - (1 * 15px));
    display: inline-block;
    vertical-align: top;
}
section#two-columns .mask.detail-blog article.small-img-section img:nth-child(2) {
    margin-left: 30px;
}
@media screen and (max-width: 950px) {
    section#two-columns .mask.detail-blog article.small-img-section img:nth-child(2) {
        margin-left: 20px !important;
    }
}


section#about-us img.about-medium-img.img2 { float: right; }
section#two-columns .mask.detail-blog article p {
    position: relative;
    margin: 25px auto 25px;
    padding: 0 20px;
    text-align: left;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: -0.020em;
    color: #888;
}
section#two-columns .mask.detail-blog article h2,
section#two-columns .mask.detail-blog article h3,
section#two-columns .mask.detail-blog article h4,
section#two-columns .mask.detail-blog article h5 {
    padding: 0 20px;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    letter-spacing: -.025em;
}
section#two-columns .mask.detail-blog article h2 strong,
section#two-columns .mask.detail-blog article h3 strong,
section#two-columns .mask.detail-blog article h4 strong,
section#two-columns .mask.detail-blog article h5 strong {
    font-weight: normal!important;
}
section#two-columns .mask.detail-blog article p:first-child { margin-top: 0; }
section#two-columns .mask.detail-blog article p:last-child { margin-bottom: 0; }
section#two-columns .mask.detail-blog article p a {
    display: inline-block;
    position: relative;
    padding: 0 0 7px;
    margin: 0;
    line-height: 1;
    color: #eb1d71;
    text-decoration: none;
}
section#two-columns .mask.detail-blog article p strong,
section#two-columns .mask.detail-blog article p b {
    font-weight: 700;
    color: #21252b;
}
section#two-columns .mask.detail-blog #interest {
    padding-bottom: 30px;
    margin-top: 130px;
}
 
section#two-columns .mask.detail-blog article p a:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(235,29,113,0.853);
}
/* what we do */
section#about-us.work .large-grid { padding: 0; }
section#about-us.work article {
    width: 100%;
    float: left;
    position: relative;
    overflow: hidden;
    margin-bottom: 130px;
}
section#about-us.work article .cell {
    width: 50%;
    float: left;
    display: table;
    overflow: hidden;
}

section#about-us.work article .container-text {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    padding: 0 130px 0 100px;
}
section#about-us.work article .cell.img img {
    width: 100%;
    max-width: 570px;
}
section#about-us.work article.left-to-right .cell.img,
section#about-us.work article.left-to-right .cell.img img {
    float: right;
}
section#about-us.work article.right-to-left .cell.img,
section#about-us.work article.right-to-left .cell.img img {
    float: left;
}
section#about-us.work article .container-text h2 {
    font-family: 'PT Serif', serif;
    font-size: 30px;
    line-height: 40px;
    font-weight: normal;
    letter-spacing: -0.025em;
}
section#about-us.work article .container-text h2 a {
    display: inline-block;
    position: relative;
    color: #eb1d71;
    text-decoration: none;
}
section#about-us.work article .container-text h2 a span {
    padding-bottom: 2px;
    position: relative;
    border-bottom: solid 1px rgba(235,29,113,0.853);
}

section#about-us.work article .container-text p {
    max-height: 148px;
    padding: 24px 0 0;
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 30px;
    color: #888;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
section#about-us.work article .container-text > a {
    display: inline-block;
    position: relative;
    padding-bottom: 8px;
    margin: 0;
    font-family: 'PT Serif', serif;
    line-height: 1;
    color: #eb1d71;
    text-decoration: none;
}
section#about-us.work article .container-text > a:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(235,29,113,0.853);
}
#interest.work { margin-top: 0; }
section#header-title.this-project2 { padding-bottom: 0; }

/* our works */
section#ajax-projects.works {
    margin-top: 85px;
}
section#header-title.blog-post.work h1 {
    max-width: 100%;
    margin-top: 0;
}
section#header-title.blog-post.work h1:after { display: none; }

/* contact */
section#about-us.contact {
    margin-top: 240px;
}
section#about-us.contact .large-title {
    width: 1170px;
    height: 100%;
    display: table;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -490px;
    z-index: 2;
}
section#about-us.contact .large-title h1 {
    display: table-cell;
    vertical-align: middle;
    padding-left: 20px;
}
section#about-us.contact .full-width:before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255,255,255,0.751);
}
#banner-about-us article.email-mobile p {
    font-size: 40px;
    line-height: 1;
    letter-spacing: -0.040em;
}
#banner-about-us article.email-mobile p:first-child { padding-bottom: 40px; }
#banner-about-us article.email-mobile p span {
    margin-top: 0;
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 1;
}
#banner-about-us article.email-mobile p a {
    display: inline-block;
    position: relative;
    padding-bottom: 5px;
    color: #fff;
    text-decoration: none;
}
#banner-about-us article.email-mobile p a:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(255,255,255,0.4);
}
.contact-info {
    width: 100%;
    float: left;
    position: relative;
    margin-top: 130px;
}
.contact-info .large-grid { padding: 0; }
.contact-info:nth-child(3) {
    padding-top: 130px;
}
@media screen and (max-width: 660px) {
    .contact-info:nth-child(3) {
        padding-top: 100px;
        margin-top: 50px;
    }
}
.contact-info:nth-child(3):before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #eaeaea;
}
@media screen and (max-width: 1190px) {
    .contact-info:nth-child(3):before {
        width: auto!important;
        left: -20px;
        right: -20px;
    }
}
.contact-info .box {
    width: 50%;
    height: 370px;
    float: left;
    display: table;
    position: relative;
    z-index: 10;
}
.contact-info .box .cell {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    margin: 0 auto;
}
.contact-info .box.contacts .cell { padding-right: 185px; }
.contact-info .box.facture .cell { padding-left: 185px; }
.contact-info .box .cell h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.025em;
}
.contact-info .box .cell p {
    margin-top: 20px;
    font-size: 16px;
    line-height: 30px;
    color: #888;
}
.contact-info .box .cell p a.map-link {
    display: inline-block;
    position: relative;
    padding-bottom: 8px;
    margin: 0;
    font-family: 'PT Serif', serif;
    font-size: 16px;
    line-height: 1;
    color: #eb1d71 !important;
    text-decoration: none;
}
.contact-info .box .cell p a.map-link:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(235,29,113,0.853);
}
.contact-info .box .cell p strong {
    color: #21252b;
}
.contact-info .box .cell p:last-child {
    font-size: 14px;
    line-height: 24px;
}
.contact-info .box .cell p:last-child a {
    color: #888;
    text-decoration: none;
}
.contact-info .box .cell .social-networks {
    width: 120px;
    height: 40px;
    display: block;
    position: relative;
    left: -2px;
    margin: 20px auto 0;
}
.contact-info:nth-child(3) .map {
    top: 130px;
}
@media screen and (max-width: 660px) {
    .contact-info:nth-child(3) .map {
        top: 0;
    }
}
.contact-info.cz .map {
    background: url("../images/map_cz.jpg") no-repeat 50%;
}
.contact-info .map {
    width: 370px;
    height: 370px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -185px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    z-index: 11;
    background: url("../images/map.jpg") no-repeat 50%;
    background-size: 100%;
}
.contact-info .map .circles {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.contact-info .map .circles svg {
    width: 100%;
    height: 100%;
}
.contact-info .map a {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
#interest.contact .texts {
    font-size: 60px;
    line-height: 70px;
}

/* catalog */
.catalog h1 {
    max-width: 570px;
    font-size: 60px;
    line-height: 70px;
    letter-spacing: -2.4px;

    @media #{$tablet1} {
        max-width: 100%;
        font-size: 50px;
        line-height: 60px;
    }
}

a.head-large-btn {
    width: 270px;
    height: 120px;
    display: table;
    position: absolute;
    top: 10px;
    right: 0;
    background: $pink;
    text-align: center;
    text-decoration: none;

    @media #{$tablet1} {
        position: relative;
        top: 0;
        margin: 50px auto 0;
    }

    @include hover {

        span span:before {
            bottom: 3px;
            background: $white;
        }
    }

    span {
        display: table-cell;
        vertical-align: middle;

        >span {
            display: inline-block;
            vertical-align: top;
            position: relative;
            padding-bottom: 6px;
            font-family: 'PT Serif', serif;
            font-size: 20px;
            letter-spacing: -0.13px;
            color: $white;
            transition: all .24s ease;

            &:before {
                width: 100%;
                height: 1px;
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                background: rgba(255,255,255,0.3);
                transition: all .24s ease;
            }
        }
    }
}

section#header-title.this-project.catalog-header {
    padding-bottom: 140px;

    @media #{$tablet1} {
        padding-bottom: 100px;
    }
}

section#catalog-icons {
    width: 100%;
    float: left;
    margin-top: 110px;
    text-align: center;

    @media #{$tablet1} {
        margin-top: 60px;
    }

    @media #{$tablet} {
        margin-top: 20px;
    }

    ul.icons-list {
        width: 100%;
        max-width: 970px;
        display: inline-block;
        vertical-align: top;
        margin: 0 auto;
        font-size: 0;

        li {
            width: 20%;
            display: inline-block;
            vertical-align: top;

            @media #{$tablet} {
                width: 33.3333%;
                width: calc(100% / 3);
                margin-top: 50px;
            }

            @media #{$mobil1} {
                width: 50%;
            }

            span {
                display: block;

                img {
                    margin: 0 auto;
                }
            }

            p {
                max-width: 170px;
                margin: 32px auto 0;
                font-family: 'PT Serif', serif;
                font-size: 16px;
                color: #000000; 
                line-height: 24px;

                @media #{$mobil7} {
                    margin: 25px auto 0;
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
    }
}

section#about-catalog {
    width: 100%;
    float: left;
    margin-top: 130px;
    text-align: center;
    font-size: 0;

    @media #{$tablet1} {
        margin-top: 75px;
    }

    .large-grid {
        padding: 0;
    }

    .about-box {
        width: 100%;
        float: left;
        position: relative;

        & + .about-box {
            margin-top: 100px;

            @media #{$tablet} {
                margin-top: 20px;

                .item.text {
                    margin-top: 85px;
                }
            }
        }

        >.item {
            width: 45%;
            width: calc(50% - 15px);
            display: inline-block;
            vertical-align: top;
            position: relative;
            text-align: left;

            @media #{$tablet} {
                width: 100%;
                display: block;
                float: left;
            }

            &:nth-of-type(2) {
                margin-left: 30px;

                @media #{$tablet} {
                    margin: 0;
                }

                p {
                    padding: 0 20px 0 0;

                    @media #{$tablet} {
                        padding: 0 20px;
                    }
                }
            }

            &.text {

                @media #{$tablet} {
                    padding-bottom: 85px;
                }

                &:before {
                    content: "";
                    display: block;
                    padding-top: 100%;

                    @media #{$desktop1} {
                        display: none;
                    }
                }

                .text-container {
                    @include absolute;
                    height: auto;
                    top: 50%;
                    @include transform(translate(0,-50%));

                    @media #{$desktop1} {
                        height: auto;
                        display: block;
                        position: relative;
                        top: 0;
                        @include transform(translate(0,0));
                    }
                }
            }

            &.img {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50%;

                &:before {
                    content: "";
                    display: block;
                    padding-top: 100%;
                }

                a {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 4;
                    text-decoration: none;
                    color: $white;
                    

                    @include hover {

                        span {
                            color: $pink;
                        }

                        &:after {
                            opacity: .928;
                        }
                    }

                    &:before {
                        width: 66px;
                        height: 66px;
                        @include pseudo;
                        bottom: 30px;
                        left: 30px;
                        border-radius: 50%;
                        z-index: 3;
                        border: 2px solid $pink;
                        background-color: $white;
                        background-position: 52% 50%;
                        background-repeat: no-repeat;
                        background-image: url("../images/icon-play.svg");
                        box-shadow: 0 4px 14px rgba(0,0,0,0.25);
                        transition: all .24s ease;
                    }

                    &:after {
                        @include absolute;
                        height: 150px;
                        top: auto;
                        bottom: 0;
                        @include pseudo;
                        transition: all .24s ease;
                        @include linearGradient(rgba(black, 0), rgba(black, 1));
                        z-index: 1;
                        opacity: .7;
                    }

                    span {
                        position: absolute;
                        bottom: 38px;
                        left: 120px;
                        font-family: 'PT Serif', serif;
                        font-size: 16px;
                        color: $white;
                        line-height: 24px;
                        text-decoration: none;
                        transition: all .24s ease;
                        z-index: 3;
                    }
                }
            }

            p {
                max-width: 370px;
                margin: 0 auto;
                position: relative;
                padding: 0 0 0 20px;
                font-size: 16px;
                font-weight: normal;
                line-height: 30px;
                color: #888888;

                @media #{$tablet} {
                    max-width: 100%;
                    padding: 0 20px;
                }

                &:not(:first-child) {
                    margin-top: 30px;

                    @media #{$tablet} {
                        margin-top: 22px;
                    }
                }

                b {
                    color: $black;
                }

                span {
                    font-size: 26px;
                    color: #000000;
                }
            }
        }
    }
}

#lightbox {
    display: none;
    @include fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: $white;

    i#close-btn {
        width: 60px;
        height: 60px;
        @include fixed;
        top: 14px;
        right: 14px;
        z-index: 10;
        cursor: pointer;

        @include hover {

            &:before,
            &:after {
                background-color: $black;
            }
        }

        &:before,
        &:after {
            width: 40px;
            height: 2px;
            @include pseudo;
            top: 50%;
            left: 50%;
            margin-left: -20px;
            background-color: $pink;
            pointer-events: none;
            transition: all 0.25s ease-in-out;
        }

        &:before {
            @include transform(rotate(-45deg));
        }

        &:after {
            @include transform(rotate(45deg));
        }
    }

    .video-container {
        width: 100%;
        max-width: 70%;
        height: 100%;
        top: 0;
        left: 15%;
        overflow: hidden;
        @include fixed;
        display: table;

        @media #{$tablet1} {
            max-width: 90%;
            left: 5%;
        }

        .video-grid {
            width: 100%;
            margin: auto;
            display: table-cell;
            vertical-align: middle;
            position: relative;
            top: 0;
            z-index: 10;
        }
    }
}

#video-wrapper {
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
    padding-bottom: 56.25%;

    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }
}




section#catalog-clmns {
    width: 100%;
    float: left;
    margin-top: 130px;
    text-align: center;
    font-size: 0;

    @media #{$tablet} {
        margin-top: 0;
    }

    .box {
        width: 40%;
        width: calc(50% - 15px);
        height: 130px;
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        border: 1px solid #EAEAEA;

        @media #{$mobil1} {
            width: 100%;
        }

        &:nth-of-type(2) {
            margin-left: 30px;

            @media #{$mobil1} {
                margin: 20px 0 0 0;
            }
        }

        >span {
            max-width: 370px;
            display: inline-block;
            vertical-align: top;
            padding: 30px;
            position: relative;
            top: 50%;
            font-size: 16px;
            color: $pink;
            line-height: 30px;
            @include transform(translate(0,-50%));
        }
    }
}

section#about-us img { width: 100%; max-width: 100%; }
#catalog-form {
    width: 100%;
    width: calc(100% / 1 - (1 * 2px));
    float: left;
    margin-top: 30px;
    padding: 130px 0 110px;
    position: relative;
    overflow: hidden;
    border: 1px solid #eb1d71;
    visibility: hidden;

    & ~ img {
        margin-top: 30px;
        float: left;

        @media #{$desktop2} {
            margin-top: 20px;
        }
    }
}
#catalog-form .form-grid {
    max-width: 768px;
    position: relative;
    margin: 0 auto;
    padding: 0 20px;
}
#catalog-form .form-grid .container {
    width: 100%;
    float: left;
    position: relative;
}
#catalog-form .form-grid .container .cell-group {
    width: 50%;
    width: calc(100% / 2 - (1 * 13px));
    float: left;
}
#catalog-form .form-grid .container .cell-group.second { float: right; }
#catalog-form .form-grid .container .group {
    width: 100%;
    width: calc(100% / 1 - (1 * 2px));
    height: 50px;
    float: left;
    position:relative;
    margin: 20px 0 0;
    padding: 7px 0 0;
    -webkit-transition: all 0.12s;
    -moz-transition: all 0.12s;
    -o-transition: all 0.12s;
    -ms-transition: all 0.12s;
    transition: all 0.12s;
    border: 1px solid #eaeaea;
    border-bottom: 2px solid #eb1d71;
}
#catalog-form .form-grid .container .group.large {
    width: 62%;
    float: right;
}
#catalog-form .form-grid .container .group.small {
    width: 34%;
    float: left;
}

#catalog-form .form-grid .container .group.medium {
    width: 48%;
    float: left;

    @media screen and (max-width: 660px) {
        width: 100%
    }

    & + .group.medium {
        float: right;

        @media screen and (max-width: 660px) {
            float: left;
        }
    }
}
#catalog-form .form-grid .container .group-text {
    width: 100%;
    width: calc(100% / 1 - (1 * 2px));
    float: left;
    position:relative;
    margin: 7px 0 0;
    // padding: 7px 0 4px;
}
#catalog-form .form-grid .container .group-text .important {
    display: block;
    margin-top: 17px;
    font-size: 12px;
    line-height: 1;
    color: #888;
}
#catalog-form .form-grid .container .group:first-child { margin-top: 0; }


#catalog-form .form-grid .container .group input,
#catalog-form .form-grid .container .group .country {
    width: 100%;
    width: calc(100% / 1 - (1 * 40px));
    padding:10px 20px 3px;
    display:block;
    position: relative;
    font-family: "PT Serif";
    font-size: 20px;
    line-height: 1;
    letter-spacing: -0.025em;
    color: #888;
    -webkit-text-fill-color: #888 !important;
    border:none;
    background: transparent;
    z-index: 3;
}
#catalog-form .form-grid .container .group .country {
    padding-bottom: 5px;
    overflow: hidden;
}
#catalog-form .form-grid .container .group .country:before {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -1px;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #eb1d6f transparent transparent transparent;
}
#catalog-form .form-grid .container .group .country,
#catalog-form .form-grid .container .group .country select {
    font-size: 14px !important;
    cursor: pointer;
}
#catalog-form .form-grid .container .group .country select {
    width: 100% !important;
    height: 22px !important;
}
#catalog-form .form-grid .container .group .country span.customSelect,
#catalog-form .form-grid .container .group .country span.customSelect span {
    width: 100% !important;
    margin-top: 2px;
}
#catalog-form .form-grid .container .group input:focus { outline:none; }
#catalog-form .form-grid .container .group label { 
    position:absolute;
    top: 23px;
    left: 20px;
    font-size:12px;
    line-height: 1;
    z-index: 1;
    text-transform: uppercase;
    -webkit-transition: all 0.26s ease;
    -moz-transition: all 0.26s ease;
    -o-transition: all 0.26s ease;
    -ms-transition: all 0.26s ease;
    transition: all 0.26s ease;
    pointer-events:none;
}

.button-container {
    width: 100%;
    height: 30px;
    position: relative;
    float: left;
    margin-top: 50px;
    text-align: center;
}
.button-container button {
    display: inline-block;
    position: relative;
    padding-bottom: 8px;
    margin: 0 auto;
    font-family: 'PT Serif', serif;
    font-size: 16px;
    line-height: 1;
    color: #eb1d71 !important;
    text-decoration: none;
    background: transparent;
    border: 0;
}
.button-container button:focus { outline: none; }
.button-container button:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(235,29,113,0.853);
}
/* active state */
.group.active,
.group.valid {
    border: 1px solid #fff !important;
    border-bottom: 2px solid #eb1d71 !important;
}
.group.invalid {
    -webkit-box-shadow: 0 0 7px rgba(235, 29, 111, 0.4);
    -moz-box-shadow: 0 0 7px rgba(235, 29, 111, 0.4);
    box-shadow: 0 0 7px rgba(235, 29, 111, 0.4);
    border: 1px solid #fff !important;
    border-bottom: 2px solid #eb1d71 !important;
}
.group.active label,
.group.active .country label,
.group.valid label,
.group.valid .country label,
.group.invalid.active label,
.group.invalid.active .country label {
    top: 0 !important;
}

.group.valid.invalid label { display: none; }





.footer-box {
    width: 100%;
    display: block;
    position: relative;
    float: left;
}
.footer-box .box {
    width: 50%;
    width: calc(100% / 2 - (1 * 15px));
    float: left;
}
.footer-box .box.first-box {
    display: table;
    background: #eb1d71;
}
.footer-box .box.first-box p {
    display: table-cell;
    vertical-align: middle;
    padding: 0 100px;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
}
.footer-box .box.first-box p strong { font-weight: 700; }
.footer-box .box.second-box { float: right; }
.footer-box .box.second-box img {
    width: 100%;
    max-width: 100%;
}


/* modal box */
#modal-box {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: hidden;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
}
#modal-box:before {
    width: 120%;
    height: 120%;
    content: "";
    position: absolute;
    top: -10%;
    left: -10%;
    background: rgba(0,0,0,0.5);
}
#modal-box .container {
    width: 100%;
    height: 100%;
    display: table;
    overflow-y: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
}
#modal-box .wrapper {
    display: table-cell;
    vertical-align: middle;
}
#modal-box .info-text {
    width: 570px;
    padding: 100px 0;
    display: block;
    margin: 0 auto;
    position: relative;
    background: #fff;
    z-index: 10;
    text-align: center;
    overflow:hidden;   
}
#modal-box .info-text h4 {
    position: relative;
    padding-top: 115px;
    font-weight: normal;
    font-size: 30px;
    line-height: 1;
    letter-spacing: 0.025em;
}
#modal-box .info-text h4:before {
    width: 66px;
    height: 66px;
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -35px;
    border: 2px solid #eb1d71;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: url("../images/icon-ok.png") no-repeat 50%;
    background: url("../images/icon-ok.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}
#modal-box .info-text.error h4:before {
    background: url("../images/icon-close.png") no-repeat 50%;
    background: url("../images/icon-close.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}
#modal-box .info-text p {
    padding: 0 100px;
    margin-top: 30px;
    font-size: 16px;
    line-height: 30px;
    color: #888;
}
#modal-box .info-text p strong { font-weight: 700;color: #21252b; }
#modal-box .info-text a.modal-close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 28px;
    right: 28px;
    background: url("../images/icon-close_black.png") no-repeat 50%;
    background: url("../images/icon-close_black.svg") no-repeat 50%, linear-gradient(transparent, transparent);
    background-size: 50%;
}


/* buy project */
section#general-large-form {
    width: 100%;
    float: left;
    padding-bottom: 130px;
}
.width-container {
    width: 50%;
    position: relative;
    float: left;
}
.width-container2 {
    width: 50%;
    position: relative;
    float: right;
}
.one-grid {
    position: relative;
    padding: 0 15px;
}
.fix { width: 50% !important; float: left !important; }
.one-grid.thefirst { padding: 0 15px 0 30px; }
.one-grid.thesecond { padding: 0 30px 0 15px; }
.cell-box {
    width: 100%;
    max-width: 570px;
    float: right;
    top: 0;
}
.cell-box.cell-form { float: left; }
.cell-box .second-box {
    width: 100%;
    float: left;
    margin-top: 30px;
    position: relative;
    background: #eb1d71;
}
.cell-box .second-box p {
    padding: 40px 50px;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
}
.cell-box .price-box {
    width: 100%;
    min-height: 120px;
    float: left;
    position: relative;
    overflow: hidden;
}
.cell-box .price-box img {
    width: 100%;
    max-width: 100%;
}
.cell-box .price-box .about-project {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0,0,0,0);
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.7)));
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
    background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
    background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}
.cell-box .price-box .about-project .info {
   position: absolute;
   bottom: 40px;
   left: 50px;
   right: 50px;
}
.cell-box .price-box .about-project .info .vertical { display: block; }
.cell-box .price-box .about-project .info span {
    font-family: "PT Serif";
    font-size: 30px;
    line-height: 1;
    color: #fff;
    letter-spacing: -0.025em;
}
.cell-box .price-box .about-project .info span.name { float: left; }
.cell-box .price-box .about-project .info span.price { float: right; }
.cell-box fieldset {
    height: 100px;
    position: relative;
    display: block;
    border: 1px solid #eaeaea;
    outline: none;
    text-align: center;
    margin-top: 30px;
}
.cell-box fieldset:nth-child(1),
.cell-box fieldset.active:nth-child(1),
.cell-box fieldset.first-field { margin-top: 0; }
.cell-box fieldset.active {
    height: auto;
    padding: 90px 0 100px;
    border: 1px solid #eb1d71;
}
.cell-box fieldset.no-active {
    border: 1px solid #eb1d71;
}
.cell-box fieldset.no-active legend { color: #eb1d71; }
.cell-box fieldset legend {
    width: 100%;
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -8px;
    text-align: center;
    font-family: "PT Serif";
    font-size: 16px;
    line-height: 1;
}
.cell-box fieldset.active legend {
    width: auto;
    display: block;
    position: relative;
    top: 0;
    left: 0;
    padding: 0 20px;
    margin: 0 auto;
}
.cell-box fieldset.active legend  {
    display: none;
}
.cell-box fieldset .field-title {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
    margin-top: -8px;
    text-align: center;
    font-family: "PT Serif";
    font-size: 16px;
    line-height: 1;
}
.cell-box fieldset.active .field-title {
    width: 100%;
    position: absolute;
    top: -9px;
    left: 0;
    margin-top: 0;
}
.cell-box fieldset .field-title div {
    display: inline;
    padding: 0 20px;
    background: #fff;
}
.bill-information {
    width: 100%;
    display: none;
    margin: 0;
    padding-top: 50px;
}
.cell-box fieldset span,
.bill-information span {
    width: 100%;
    width: calc(100% / 1 - (1 * 40px));
    max-width: 370px;
    display: block;
    margin: 0 auto;
    padding-bottom: 10px;
    text-align: left;
    font-family: "PT Serif";
    line-height: 1;
}
.cell-box fieldset span { display: none; }
.cell-box fieldset.active span { display: block; }
.cell-box fieldset .ok-label {
    width: 100%;
    width: calc(100% / 1 - (1 * 40px));
    max-width: 370px;
    display: none;
    margin: 20px auto 0;
    position: relative;
    text-align: left; 
}
.cell-box fieldset.active .ok-label { display: block; }
.cell-box fieldset.active .ok-label span { display: inline-block; }
.cell-box fieldset .ok-label.ok2 { margin: 0 auto 0;padding-bottom: 8px; }

.cell-box fieldset .ok-label input {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.cell-box fieldset .ok-label label {
    width: 26px;
    height: 26px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border: 2px solid #ccc;
    -webkit-transition: all 0.14s linear;
    -moz-transition: all 0.14s linear;
    -o-transition: all 0.14s linear;
    -ms-transition: all 0.14s linear;
    transition: all 0.14s linear;
}
 .cell-box fieldset .ok-label label:before,
 .cell-box fieldset .ok-label label:after {
    width: 16px;
    height: 2px;
    content: "";
    position: absolute;
    top: 12px;
    left: 5px;
    background: #ccc;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.14s linear;
    -moz-transition: all 0.14s linear;
    -o-transition: all 0.14s linear;
    -ms-transition: all 0.14s linear;
    transition: all 0.14s linear;
}
.cell-box fieldset .ok-label label:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}
.cell-box fieldset .ok-label label:after{
    -webkit-transform: rotate(-48deg);
    -moz-transform: rotate(-48deg);
    transform: rotate(-48deg);
}
.cell-box fieldset .ok-label input:checked ~ label {
    border: 2px solid #eb1d6f;
}
.cell-box fieldset .ok-label input:checked ~ label:before {
    width: 7px;
    top: 14px;
    left: 6px;
    background: #eb1d6f;
    -webkit-transform: rotate(-140deg);
    -moz-transform: rotate(-140deg);
    transform: rotate(-140deg);
}
.cell-box fieldset .ok-label input:checked ~ label:after {
    width: 12px;
    left: 10px;
    background: #eb1d6f;
    -webkit-transform: rotate(-225deg);
    -moz-transform: rotate(-225deg);
    transform: rotate(-225deg);
}
.cell-box fieldset .ok-label span {
    display: inline-block;
    margin-top: 6px;
    padding-left: 42px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
}
.small-info {
    width: 100%;
    width: calc(100% / 1 - (1 * 40px));
    max-width: 370px;
    display: none;
    margin: 10px auto 0;
    position: relative;
    text-align: left;
    font-size: 12px;
    line-height: 18px;
    color: #888;
}
.small-info.nth1 { padding-bottom: 10px; }
.cell-box fieldset.active .small-info { display: block; }


.cell-box .group {
    width: 100%;
    width: calc(100% / 1 - (1 * 42px));
    max-width: 368px;
    height: 50px;
    display: none;
    text-align: left !important;
    position:relative;
    margin: 10px auto 0;
    padding: 7px 0 0;
    font-size: 0;
    -webkit-transition: all 0.12s;
    -moz-transition: all 0.12s;
    -o-transition: all 0.12s;
    -ms-transition: all 0.12s;
    transition: all 0.12s;
    border: 1px solid #eaeaea;
    border-bottom: 2px solid #eb1d71;
}
.cell-box fieldset.active .group.large {
    width: 234px;
    display: inline-block;
    margin-left: 8px;
    vertical-align: top;
}
.cell-box fieldset.active .group.small {
    width: 120px;
    display: inline-block;
    vertical-align: top;
}
.cell-box fieldset.active .group { display: block; }


.cell-box .group input,
.cell-box .group .country {
    width: 100%;
    width: calc(100% / 1 - (1 * 40px));
    padding:10px 20px 3px;
    display:block;
    position: relative;
    font-family: "PT Serif";
    font-size: 20px;
    line-height: 1;
    letter-spacing: -0.025em;
    color: #888;
    -webkit-text-fill-color: #888 !important;
    border:none;
    background: transparent;
    z-index: 3;
}
.cell-box .group.text { height: auto;margin-top: 20px; }
.cell-box .group textarea {
    width: 100%;
    width: calc(100% / 1 - (1 * 40px));
    padding:10px 20px;
    height: 150px;
    display:block;
    position: relative;
    font-family: "PT Serif";
    font-size: 20px;
    line-height: 1;
    letter-spacing: -0.025em;
    color: #888;
    -webkit-text-fill-color: #888 !important;
    border:none;
    background: transparent;
    z-index: 3;
    resize: none;
}
.cell-box .group .country {
    overflow: hidden;
    padding-bottom: 5px;
}
.cell-box .group .country:before {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -1px;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #eb1d6f transparent transparent transparent;
}
.cell-box .group .country,
.cell-box .group .country select {
    font-size: 14px !important;
    cursor: pointer;
}
.cell-box .group .country select {
    width: 100% !important;
    height: 22px !important;
}
.cell-box .group .country span.customSelect,
.cell-box .group .country span.customSelect span {
    width: 100% !important;
    margin-top: 2px;
    padding-bottom: 0 !important;
}
.cell-box .group input:focus { outline:none; }
.cell-box .group label { 
    position:absolute;
    top: 23px;
    left: 20px;
    font-size:12px;
    line-height: 1;
    z-index: 1;
    text-transform: uppercase;
    -webkit-transition: all 0.26s ease;
    -moz-transition: all 0.26s ease;
    -o-transition: all 0.26s ease;
    -ms-transition: all 0.26s ease;
    transition: all 0.26s ease;
    pointer-events:none;
}
.important-value {
    width: 100%;
    display: block;
    float: left;
    margin-top: 20px;
    font-size: 12px;
    line-height: 1;
    color: #888;
}
/* list in text */
article.text-section ul li {
    position: relative;
    padding: 0 20px 0 44px;
    text-align: left;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: -0.020em;
    color: #888;
}
article.text-section ul li:before {
    width: 8px;
    height: 8px;
    content: "";
    position: absolute;
    top: 17px;
    left: 20px;
    background: #eb1d71;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}







@media screen and (max-width: 1420px) {
}
@media screen and (max-width: 1200px) {
    ul#project-list li { width: 33.3333%;width: calc(100% / 3); }
    #home-three .three-sections a {
        padding: 0 20px 6px;
        font-size: 50px;
        line-height: 60px;
    }
    #home-three .three-sections p {
        padding: 0 20px;
    }
    .slide-counter {
        width: 66px;
        height: 66px;
        position: absolute;
        top: 100%;
        right: 50%;
        margin-right: -35px;
        margin-top: -35px;
    }
    
}
@media screen and (max-width: 1190px) {
    #interest { border-top: 0; }
    #interest:before {
        width: 140%;
        height: 1px;
        content: "";
        position: absolute;
        top: 0;
        left: -20%;
        background: #eaeaea;
    }
    section#about-us img.about-medium-img {
        width: calc(100% / 2 - (1 * 10px));
        margin-top: 20px;
    }
    #catalog-form { margin-top: 20px; }
    .footer-box .box {
        width: calc(100% / 2 - (1 * 10px));
    }
    #catalog-form .form-grid .container .cell-group {
        width: calc(100% / 2 - (1 * 10px));
    }
    .one-grid.thefirst { padding: 0 10px 0 20px; }
    .one-grid.thesecond { padding: 0 20px 0 10px; }
    .cell-box .second-box { margin-top: 20px; }
}
@media screen and (max-width: 1170px) {
    section#about-us.contact .large-title {
        left: 0;
        margin-left: 0;
    }
}
@media screen and (min-width: 1024px) {
    #large-nav .section a:before,
    ul#project-list li a.large-link .title h2,
    ul#project-list li a.small-link,
    ul#project-list li a.project-banner .row .content span.medium-link,
    ul#project-list li a.project-banner .row .content span.medium-link:before,
    a.gnr-link,
    a.gnr-link:before,
    #home-sct .text .small-slider .bx-pager-item a,
    #home-three .three-sections a,
    #home-three .three-sections a span:before,
    #nav-footer .adress a span:before,
    #nav-footer .adress a span,
    #logo span,
    ul#project-list li a.small-link span:before,
    ul#project-list li a.small-link span,
    #video-trns .apex-title .container a,
    #video-trns .apex-title .container a:before,
    #about-table-project a,
    #about-table-project a:before,
    #interest a,
    #interest a:before,
    #we-are .profile-box .container .name a,
    #we-are .profile-box .container .name a:before,
    ul#partners-list li,
    ul#project-list li a.project-banner,
    ul.blog-filter li a,
    .date a,
    .date a:before,
    ul.blog-filter li a:before,
    section#two-columns .mask.detail-blog p a,
    section#two-columns .mask.detail-blog p a:before,
     
    section#about-us.work article .container-text > a,
    section#about-us.work article .container-text > a:before,
    #banner-about-us article.email-mobile p a,
    #banner-about-us article.email-mobile p a:before,
    section#header-title p a,
    section#header-title p a:before,
    .contact-info .box .cell p a.map-link,
    .contact-info .box .cell p a.map-link:before,
    .button-container button,
    .button-container button:before,
    .tt-suggestion,
    section#about-us.work article .container-text h2 a span,
    .tt-suggestion span {
        -webkit-transition: all 0.24s ease;
        -moz-transition: all 0.24s ease;
        -o-transition: all 0.24s ease;
        -ms-transition: all 0.24s ease;
        transition: all 0.24s ease;
    }
    .fade-linear,
    .fade-linear:after,
    .fade-linear:before {
        -webkit-transition: background 0.22s linear;
        -moz-transition: background 0.22s linear;
        -o-transition: background 0.22s linear;
        -ms-transition: background 0.22s linear;
        transition: background 0.22s linear;
    }
    .fade-border,
    .fade-border:after,
    .fade-border:before,
    .group {
        -webkit-transition: border 0.22s linear;
        -moz-transition: border 0.22s linear;
        -o-transition: border 0.22s linear;
        -ms-transition: border 0.22s linear;
        transition: border 0.22s linear;
    }
    
    #general-footer .footer-nav a,
    #general-footer .copyright a,
    #sitemap a,
    ul#project-list.blog li .date {
        -webkit-transition: color 0.22s ease;
        -moz-transition: color 0.22s ease;
        -o-transition: color 0.22s ease;
        -ms-transition: color 0.22s ease;
        transition: color 0.22s ease;
    }
    .ele {
        -webkit-transition: opacity 0.24s ease;
        -moz-transition: opacity 0.24s ease;
        -o-transition: opacity 0.24s ease;
        -ms-transition: opacity 0.24s ease;
        transition: opacity 0.24s ease;
    }
    #nav a:hover span:before { top: -8px; }
    #nav a:hover span:after { top: 8px; }
    ul#project-list li a.project-banner:hover .row .content span.medium-link { color: #fff; }
    #video-trns .apex-title .container a:hover,
    #about-table-project a:hover,
    #interest a:hover,
    #we-are .profile-box .container .name a:hover,
    ul.blog-filter li a:hover,
    section#two-columns .mask.detail-blog p a:hover,
    section#about-us.work article .container-text h2 a:hover,
    section#about-us.work article .container-text a:hover,
    section#header-title p a:hover,
    .contact-info .box .cell p a.map-link:hover,
    .button-container button:hover { color: #21252b !important; }
    #large-nav .section a:hover:before,
    ul#project-list li a.project-banner:hover .row .content span.medium-link:before,
    a.gnr-link:hover:before,
    #video-trns .apex-title .container a:hover:before,
    #about-table-project a:hover:before,
    #interest a:hover:before,
    #we-are .profile-box .container .name a:hover:before,
    ul.blog-filter li a:hover:before,
    section#two-columns .mask.detail-blog p a:hover:before,
    section#about-us.work article .container-text h2 a:hover:before,
    section#about-us.work article .container-text a:hover:before,
    section#header-title p a:hover:before,
    .contact-info .box .cell p a.map-link:hover:before,
    .button-container button:hover:before { bottom: 3px; }
    #large-nav .section a:hover:before { background: rgba(255,255,255,0.9); }
    #nav-footer .adress a:hover span,
    ul#project-list li a.small-link:hover { color: rgba(255,255,255,1); }
    ul#project-list li a.small-link:hover span:before { bottom: -2px;background: rgba(255,255,255,0.9); }
    #home-three .three-sections a:hover span:before { bottom: 0; }
    #nav-footer .adress a:hover span:before,
    #banner-about-us article.email-mobile p a:hover:before { background: rgba(255,255,255,0.9);bottom: 2px; }
    .nieco { opacity: .6 }
    a.gnr-link:hover,
    #home-three .three-sections a:hover,
    #general-footer .footer-nav a:hover,
    #general-footer .copyright a:hover { color: #21252b;text-decoration: none; }
    #home-sct .text .small-slider .bx-pager-item a:hover {
        border-color: #dedede;
    }
    #home-sct .text .small-slider .bx-pager-item a.active:hover { border-color: #eb1d71; }
    #sitemap a:hover { color: #21252b; }
    a.ntw:hover:before {
        -webkit-transform: scale3d(.5,.5,.5);
        -moz-transform: scale3d(.5,.5,.5);
        -ms-transform: scale3d(.5,.5,.5);
        -o-transform: scale3d(.5,.5,.5);
        transform: scale3d(.5,.5,.5);
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    .social-networks a.ntw.link-facebook-pink:hover:after {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        background: url("../images/icon-fb.png") no-repeat 50%;
        background: url("../images/icon-fb.svg") no-repeat 50%, linear-gradient(transparent, transparent);
    }
    .social-networks a.ntw.link-pinterest-pink:hover:after {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        background: url("../images/icon-pi.png") no-repeat 50%;
        background: url("../images/icon-pi.svg") no-repeat 50%, linear-gradient(transparent, transparent);
    }
    .social-networks a.ntw.link-instagram-pink:hover:after {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        background: url("../images/icon-in.png") no-repeat 50%;
        background: url("../images/icon-in.svg") no-repeat 50%, linear-gradient(transparent, transparent);
    }

    .social-networks a.ntw.link-youtube-pink:hover:after {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        background: url("../images/icon-yt.svg") no-repeat 50%, linear-gradient(transparent, transparent);
    }

    .socials a.ntw.link-facebook:hover:after {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        background: url("../images/icon-fb_pink.png") no-repeat 50%;
        background: url("../images/icon-fb_pink.svg") no-repeat 50%, linear-gradient(transparent, transparent);
    }
    .socials a.ntw.link-pinterest:hover:after {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        background: url("../images/icon-pi_pink.png") no-repeat 50%;
        background: url("../images/icon-pi_pink.svg") no-repeat 50%, linear-gradient(transparent, transparent);
    }
    .socials a.ntw.link-instagram:hover:after {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        background: url("../images/icon-in_pink.png") no-repeat 50%;
        background: url("../images/icon-in_pink.svg") no-repeat 50%, linear-gradient(transparent, transparent);
    }
    .socials a.ntw.link-youtube:hover:after {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        background: url("../images/icon-yt_pink.svg") no-repeat 50%, linear-gradient(transparent, transparent);
    }
    
ul#project-list li:hover img.preload-zoom {
    -webkit-transform: scale(1.08,1.08);
    -moz-transform: scale(1.08,1.08);
    -o-transform: scale(1.08,1.08);
    -ms-transform: scale(1.08,1.08);
    transform: scale(1.08,1.08);
    -webkit-transition: 5s ease-out;
    -moz-transition: 5s ease-out;
    -ms-transition: 5s ease-out;
    -o-transition: 5s ease-out;
    transition: 5s ease-out;
}
ul#sketch-slider li a:hover:before,
#large-sketch .container-large-img a:hover:before {
    -webkit-transform: scale3d(1,1,1);
    -moz-transform: scale3d(1,1,1);
    -ms-transform: scale3d(1,1,1);
    -o-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1);
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
ul#sketch-slider li a:hover:after,
#large-sketch .container-large-img a:hover:after {
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
ul#partners-list li:hover {
    -webkit-box-shadow: 0 7px 14px rgba(0,0,0,0.1);
    -moz-box-shadow: 0 7px 14px rgba(0,0,0,0.1);
    box-shadow: 0 7px 14px rgba(0,0,0,0.1);
}
ul#project-list li:hover a.project-banner {
    color: #fff;
    background: #eb1d71;
}
ul#project-list li a.large-link .title h2,
ul#project-list li a.project-banner .row .content h2 {
    -webkit-transition: all 0.24s ease;
    -moz-transition: all 0.24s ease;
    -o-transition: all 0.24s ease;
    -ms-transition: all 0.24s ease;
    transition: all 0.24s ease;
}
ul#project-list li:hover .row .content > h2 {
    color: #fff!important;
}
ul#project-list li:hover a {
    color: #fff;
}
ul#project-list li:hover a.large-link .title h2 {
    color: #fff;
}
.date.detail a:hover:before { bottom: 2px; }
ul#project-list li:hover .date a:hover { padding-bottom: 0;color: #fff;border-bottom: 1px solid rgba(255,255,255,0.643); }
.all-blog ul#project-list li:hover .date a:hover { padding-bottom: 0;color: #fff;border-bottom: 1px solid rgba(255,255,255,0.643); }
.blog-post .date.detail a:hover { color: #21252b; }
ul#project-list li:hover .date { color: #f79bc1; }
ul#project-list li:hover .date a { color: #f79bc1;border-bottom: 1px solid #ff74ad; }
ul#project-list li:hover a.project-banner .row .content:before,
ul#project-list li:hover a.project-banner .row .content:after { display: none; }
ul#project-list li:hover a.project-banner .row .content span.medium-link { color: #fff; }
ul#project-list li:hover a.project-banner .row .content span.medium-link:before { background: rgba(255,255,255,0.643);}

.group:hover {
    border: 1px solid #cccccc;
    border-bottom: 2px solid #eb1d71;
}
section#about-us.work article .container-text h2 a:hover span { padding-bottom: 0px; }

}

@media screen and (min-width: 1100px) {
    #fb-large-button .fb-container {
        -webkit-box-shadow: 0 4px 14px rgba(0,0,0,0.25);
        -moz-box-shadow: 0 4px 14px rgba(0,0,0,0.25);
        box-shadow: 0 4px 14px rgba(0,0,0,0.25);
    }
}
@media screen and (max-width: 1100px) {
    #sitemap { display: none; }
    #fb-large-button {
        width: 100%;
        float: left;
        margin-top: 130px;
        position: relative;
        top: 0;
        bottom: 0;
        text-align: center;
    }
}
@media screen and (max-width: 1030px) {
    section#header-title,
    section#header-title.medium,
    section#header-title.error {
        padding: 200px 0 100px;
    }
    section#header-title.blog-post {
        padding: 200px 0 0;
    }
    section#header-title.this-project { padding-bottom: 100px; }
    section#partners { margin-top: 0; }

}
@media screen and (max-width: 1010px) {
    #interest a:before { display: none; }
    #interest a { line-height: 40px; }
    #interest a span {
        position: relative;
        padding-bottom: 3px;
        border-bottom: 1px solid rgba(235,29,113,0.853);
    }
    .text-container .box {
        padding: 0 60px;
    }
    .text-container:nth-child(1) .box { padding: 0 80px 0 60px; }
    .text-container:nth-child(2) .box{ padding: 0 60px 0 80px; }
    section#about-us.work article .container-text {
        padding: 0 100px 0 70px;
    }
    section#about-us.work article .container-text p {
        max-height: 90px;
        -webkit-line-clamp: 3;
    }
    .footer-box .box.first-box p { padding: 0 60px; }
    .cell-box .second-box p {
        padding: 50px;
    }

}
@media screen and (max-width: 950px) {
    section#mesh-animation .large-grid { padding: 0;padding-top: 40px; }
    #video-trns .animation { display: none; }
    #video-trns .apex-title { height: auto !important; position: relative; margin: 0 auto; }
    #video-trns .apex-title .container { width: 100%;margin-left: 0; text-align: center;  }
    #video-trns .apex-title .container h1 { max-width: 800px; margin: 0 auto; }
    #video-trns .apex-title .container:first-child a { margin-right: 12px; }
    #video-trns .apex-title .container:last-child a { margin-left: 12px; }
    section#two-columns .mask.detail-blog article.small-img-section { margin-top: 20px; }
    section#two-columns .mask.detail-blog article.small-img-section img {
        width: calc(100% / 2 - (1 * 10px));
    }
    #banner-about-us.contact {
        width: 100%;
        height: auto;
        float: none;
        margin-top: 0;
    }
    .full-width.contact { height: auto;padding: 70px 0; }
    #banner-about-us.contact article.email-mobile { text-align: center; }
    #banner-about-us.contact article.email-mobile p { padding: 50px; }
    #banner-about-us article.email-mobile p:first-child { padding-bottom: 0; }
    section#about-us.contact .large-title {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        padding-bottom: 70px;
        margin-left: 0;
    }
    section#about-us.contact .large-title h1 {
        padding-left: 0;
        text-align: center;
    }
    .cell-box .price-box .about-project .info span {
        display: block;
        text-align: center;
        margin-top: 20px;
    }
    .cell-box .price-box .about-project .info span.name { float: none; }
    .cell-box .price-box .about-project .info span.price { float: none; }
    
}

@media screen and (max-width: 900px) {
    header .header-container { padding: 30px 40px 0; }
    h1,
    section#header-title.blog-post h1 { text-align: center;font-size: 50px;line-height: 60px; }
    h1.hack1 { margin-left: 0; }
    #logo a { width: 124px; height:32px;background-size: auto 100%; }
    #logo span { margin: 8px 0 0 26px; }

    #home-sct.mask { padding: 84px 0 0; }
    #home-sct .title h3 { padding: 0 0 0 50px;font-size: 50px;line-height: 60px; }
    #home-sct .text > p { padding: 0 50px; }
    #home-sct .text > a { margin: 30px 0 0 50px; }
    #home-sct .title .small-nav { margin-left: 50px; }
    #home-sct .text .small-slider { margin: 0 0 0 50px; }
    #home-sct .text .small-slider ul li p { padding-right: 50px; }
    #home-three .three-sections a { font-size: 30px; line-height: 40px; }
    
    
    #large-sketch {
        width: 100%;
        float: left;
        position: relative;
        margin-top: 50px;
        padding: 0 0;
    }
    #large-sketch .container-large-img,
    #large-sketch .container-large-img a {
        height: auto;
    }
    #large-sketch .container-large-img img {
        position: relative;
    }
    #project-table {
        width: 100%;
        max-width: 100%;
        height: 362px;
        float: left;
        position: relative;
        margin-top: 100px;
        outline: 4px solid #eb1d71;
        border: 0;
    }
    #small-sketch { top: -60px; }
    #project-table .row1 .column1 { width: 30%; }
    #project-table .row1 .column2 { width: 69%; }
    #about-table-project { max-width: 100%; float: left; }
    #about-table-project a { margin: 30px auto 0; }
    section#header-title.medium p,
    section#header-title p {
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
    }
    #we-are .profile-box .container .img-profile {
        width: 280px;
        height: 280px;
    }
    #we-are .profile-box:nth-child(1) .container .img-profile,
    #we-are .profile-box:nth-child(2) .container .img-profile {
        background-size: 100%;
    }
    section#ajax-projects.all-projects.all-blog,
    section#ajax-projects.works { margin-top: 0; }
    ul.blog-filter { text-align: center; }
    ul.blog-filter li { margin: 20px 13px 0; }
    .date.detail {
        margin: 0 auto;
        text-align: center;
    }
    section#two-columns .mask.detail-blog article,
    section#two-columns .mask.detail-blog .large-slider,
    #fb-large-button { margin-top: 100px; }
    section#about-us.contact {
        margin-top: 112px;
    }
    .contact-info .map {
        width: 300px;
        height: 300px;
        margin-left: -150px;
    }
    .contact-info .box { height: 300px; }
    .contact-info .box.contacts .cell { padding-right: 150px; }
    .contact-info .box.facture .cell { padding-left: 150px; }
    #interest.catalog { margin-top: 100px; }
    .cell-box fieldset.active { padding: 60px 0 70px; }
    ul#project-list li { width: 50%;width: calc(100% / 2); }
    ul#project-list li:nth-of-type(3),
    ul#project-list li:nth-of-type(8) {
        display: none;
    }
}
@media screen and (max-width: 800px) {
    
    ul#project-list li.category + li a.large-link:before { display: none; }
    
    #home-three .three-sections p {
        margin: 25px auto 0;
    }
    #we-are:after {
        right: -500px;
    }

}
@media screen and (max-width: 790px) {
    .select-box.sml1 { margin-left: 0; }
}

@media screen and (max-width: 768px) {
    #home-sct .title,
    #home-sct .text { width: 100%; }
    #home-sct .title h3 { padding: 0 20px;text-align: center;margin: 0 auto; }
    #home-sct .text > p { padding: 0 20px;text-align: center;margin-top: 30px; }
    #home-sct .text > a { display: table; margin: 30px auto 0; }

    #home-sct {
        font-size: 0;
    }

    #home-sct .img-box {
        width: 33.3333%;
        width: calc(100% / 3);
        display: inline-block;
        vertical-align: top;
        margin-top: 100px;
    }

    .img-about {
        width: 100%;
        position: relative;

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
    }

    .img-box-about {
        display: none;
    }

    #home-sct.large-grid {
    
        .width-section {
            margin-top: 80px;
        }
    }

    .width-section.img-section {
        padding-bottom: 0;
    }

    #home-sct .img-box.img1 { margin-top: 100px; }
    #home-sct .img-box.img1 { top: 0; padding-right: 0; }
    #home-sct .img-box.img2 { top: 0; padding-left: 0; }
    #home-sct .img-box.img3 {
        position: relative;
        margin-top: 100px;
        -webkit-transform: translate(0,0);
        transform: translate(0,0);
    }
    #home-sct .img-box img {
        width: auto;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%,0);
        -moz-transform: translate(-50%,0);
        -ms-transform: translate(-50%,0);
        -o-transform: translate(-50%,0);
        transform: translate(-50%,0);
        z-index: 1;
    }
    #home-sct .img-box .container-img ul li {
        width: 100%;
        display: inline-block;
        position: relative;
        overflow: hidden;
        vertical-align: top;
    }
    #home-sct .img-box .container-img ul li:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    #home-sct .img-box .container-img ul li .img-about {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    #home-sct .title .small-nav {
        width: 320px;
        margin: 0 auto;
    }
    #home-sct .title .small-nav span { margin: 100px 0 20px 0;text-align: center; }
    #home-sct .img-box.img1 .container-img.cnt1 { display: none; }
    #home-sct .text .small-slider { top: 0; margin: 0; }
    #home-sct .text .small-slider span { margin: 0;text-align: center; }
    #home-sct .text .small-slider ul { top: 0; }
    #home-sct .text .small-slider .slide-title { margin: 30px auto 0;display: table; }
    #home-sct .text .small-slider ul li p { padding: 0 20px;text-align: center; }
    #home-three { margin-top: 60px; }
    #home-sct .text .small-slider .bx-controls.bx-has-pager { float: none; }

    section#two-columns { padding-bottom: 35px; }
    section#two-columns .grid-row { padding-bottom: 0; }
    section#two-columns .grid-row .grid-box { width: 100%; }
    section#two-columns .grid-row .grid-box .container p { padding-right: 0; }
    .text-container { width: 100%; }
    .text-container .box {
        padding: 0 40px;
    }
    .text-container:nth-child(1) .box { padding: 0 40px; }
    .text-container:nth-child(2) .box{ padding: 0 40px; }
    .text-container:nth-child(2) { margin-top: 100px; }
    section#text-img { padding-bottom: 100px; }
    section#text-img .text-container:nth-child(2) { padding-bottom: 0; }
    section#text-img .text-container:nth-child(2) { margin-top: 40px; }
    section#two-texts { padding-bottom: 100px; }
    section#two-texts .large-grid .mask { padding: 100px 0; }
    section#text-img #interest { margin-top: 0; }
    section#about-us { padding-bottom: 100px; }
    section#about-us article.all-text {
        padding: 55px 0 90px;
    }
    section#about-us article.all-text p,
    section#two-columns .mask.detail-blog p {
        font-size: 16px;
        line-height: 30px;
    }
    section#two-columns .mask.detail-blog p a {
        padding-bottom: 6px;
    }
    #interest.no-border .texts,
    #interest.contact .texts {
        font-size: 30px;
        line-height: 1;
    }
    section#about-us img.about-large-img { margin-top: 100px; }
    section#partners { margin-top: 0; }
    ul#partners-list li a span { height: 78px; }
    section#about-us.work article { margin-bottom: 100px; }
    section#about-us.work article .container-text {
        padding: 0 40px 0 30px;
    }
    section#about-us.work article .container-text p {
        margin-bottom: 24px;
    }
    #interest.work { padding-top: 100px; }
    .contact-info .map {
        width: 270px;
        height: 270px;
        margin-left: -135px;
        top: 20px;
    }
    .contact-info .box.contacts .cell { padding-right: 135px; }
    .contact-info .box.facture .cell { padding-left: 135px; }
    #catalog-form {
        padding: 100px 0;
    }
    .cell-box .second-box p {
        padding: 30px;
    }
    .cell-box fieldset.active { padding: 40px 0 50px; }
    #home-sct .title .small-nav li { width: 100%; text-align: center; }
    #home-sct .title .small-nav li:nth-child(even) { width: 100% }
}
@media screen and (max-width: 740px) {
    #we-are { height: auto;padding: 100px 0; }
    #we-are .profile-box { width: 100%; }
    #we-are .profile-box:nth-child(1) { margin-bottom: 200px; }
    #we-are .profile-box:nth-child(1):before {
        width: 100%;
        margin-top: 0;
        top: 100%;
        margin-top: 55px;
        font-size: 80px;
    }
    #we-are .profile-box .container .name { padding-top: 38px; }
    ul#partners-list li {
        width: 33.3333%;
        width: calc(100% / 3);
    }
    ul#partners-list li a span { height: 60px; }
    .footer-box .box.first-box p { padding: 0 30px; }
    .width-container,
    .width-container2 {
        width: 100%;
    }
    .cell-box { max-width: 100%; }
    .one-grid.thefirst { padding: 0 20px; }
    .one-grid.thesecond { padding: 0 20px; }
    .cell-box.cell-form { float: left; margin-top: 60px; }
    .fix { display: none !important; }

    
}

@media screen and (max-height: 600px) {
    #modal-box .container { display: block; }
    #modal-box .wrapper {
        width: auto;
        display: block;
    }
}
@media screen and (max-width: 660px) {
    #large-nav { width: 100%;display: block; }
    #large-nav .section {
        display: block;
        padding: 0;
    }

    #large-nav .section .link { text-align: center; }
    #general-footer .footer-nav { display: none; }
    #general-footer .copyright { margin-top: 50px; }
    .value-box span.selected {
        margin-top: 6px;
        padding-bottom: 10px;
    }
    .select-options { top: 46px; }
    ul#sketch-slider li a,
    #large-sketch a {
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
    #large-sketch {
        width: 100%;
        float: left;
        position: relative;
        margin-top: 0;
        padding: 0 0;
    }
    #project-table {
        margin-top: 50px;
    }
    #small-sketch {
        position: relative;
        float: left;
        margin-top: 0;
    }
    section#project-sketch { padding-bottom: 100px; }
    #interest { margin-top: 50px;padding-top: 100px; }
    section#two-columns .mask.detail-blog #interest {
        padding-bottom: 0;
        margin-top: 100px;
    }
    section#two-columns .mask.detail-blog p ~ #interest { margin-top: 100px; }
    #banner-about-us {
        width: auto;
        height: auto;
        float: none;
        margin-top: 0;
    }
    .full-width { height: auto;padding: 70px 0; }
    #banner-about-us article p { padding: 50px; }
    section#about-us.work article .cell { width: 100%;height: auto !important; }
    section#about-us.work article .cell.img img { max-width: 100%; }
    section#about-us.work article .container-text { padding: 40px 40px 0; }
    .contact-info .map {
        width: 270px;
        height: 270px;
        float: left;
        margin: 60px auto 60px -135px;
        top: 0;
        position: relative;
        display: block;
    }
    .contact-info .box {
        width: 100%;
        height: auto;
        display: block;
        text-align: center;
    }
    .contact-info .box .cell { width: 100%;display: block; }
    .contact-info .box.contacts .cell { padding-right: 0px; }
    .contact-info .box.facture .cell { padding-left: 0px; }
    .contact-info { padding-bottom: 50px; margin-top: 100px; }
    #catalog-form .form-grid .container .group.large {
        width: 100%;
        float: left;
    }
    #catalog-form .form-grid .container .group.small {
        width: 100%;
    }
    .footer-box .box {
        width: 100%;
        width: calc(100% / 1 - (1 * 0px));
        float: left;
    }
    .footer-box .box.first-box {
        height: auto !important;
        display: block;
    }
    .footer-box .box.first-box p { padding: 60px 30px; }
    .footer-box .box.second-box { margin-top: 20px; }
    #modal-box .container { display: block; }
    #modal-box .wrapper {
        width: auto;
        display: block;
    }
    #small-sketch { top: 0; }
    .view-add { margin-top: 0; }
    .select-box.sml1 { margin-left: 0; }

    #logo span { display: none; }
 ul#project-list li { width: 100%;width: calc(100% / 1);  }

 ul#project-list li:nth-of-type(2) {
    border-bottom: 1px solid #eaeaea;
 }
}

@media screen and (max-width: 540px) {
       
    ul#project-list li.arrow > a.large-link:before {
        display: block;
        top: 0;
        left: 50%;
        margin-left: -20px;
        margin-top: 0;
        border-width: 15px 20px 0 20px;
        border-color: #eb1d6f transparent transparent transparent;
    }
    ul#project-list.blog li a.project-banner {
        border-right: 0;
    }
    section#ajax-projects.all-projects ul#project-list li { display: block !important; }
    // ul#project-list li:nth-child(1),
    // ul#project-list li:nth-child(2),
    // ul#project-list li:nth-child(3),
    // ul#project-list li:nth-child(4) { display: block !important; }
    #general-footer .copyright a {
        margin: 40px auto 0;
    }
    #home-three .three-sections { width: 100%;width: calc(100% / 1);text-align: center; }
    #home-three .three-sections a { font-size: 50px; line-height: 60px; }
    section#header-title,
    section#header-title.medium,
    section#header-title.error {
        padding: 155px 0 100px;
    }
    section#header-title.blog-post {
        padding: 155px 0 0;
    }
}
@media screen and (max-width: 520px) {
    #project-table { height: auto; }
    #project-table .row1 { height: auto; }
    #project-table .row1 .column1 { width: 100%;border-right: 0;border-bottom: 1px solid #eb1d71; }
    #project-table .row1 .column1 .box { width: 50%;height: 130px; float: left; }
    #project-table .row1 .column1 .box:first-child:before {
        width: 1px;
        height: 100%;
        content: "";
        top: 0;
        bottom: 0!important;
        right: 0;
        left: 100%;
    }
    #project-table .row1 .column2 { width: 100%;padding: 20px 0; }
    #project-table .row1 .column2 .box .container { padding: 0 24px; }
    #project-table .row2 { height: auto; float: left; }
    #project-table .row2 .column { width: 100%; }
    #project-table .row2 .column .box { padding: 24px 0; }
    #project-table .row2 .column:first-child:before {
        width: 100%;
        height: 1px;
        top: 100%;
        right: 0!important;
    }
    #about-table-project a {
        margin: 50px auto 0;
    }
    #banner-about-us.contact article.email-mobile p { font-size: 30px; }
    #banner-about-us.contact article.email-mobile p { padding: 50px 20px; }
    #banner-about-us.contact article.email-mobile p:first-child { padding-bottom: 0; }
}
@media screen and (max-width: 480px) {

    #home-sct .img-box {
        width: 100%;
        display:block;
        margin-top: 0;
    }


    #home-sct .img-box.img1 { margin-top: 100px; }
    #home-sct .img-box.img3 {
        margin-top: 0;
    }



    ul.detail-product-slider li {
        height: 260px;
    }
    ul.detail-product-slider li img {
        width: auto;
        height: 100%;
    }
    ul.detail-product-slider li span {
        width: 100%;
        height: 260px;
        display: block;
        overflow: hidden;
        background: no-repeat 100% 0;
        background-size: auto 100%;
    }
    ul.detail-product-slider li.active-slide span {
        -webkit-animation: sliderbg 5s forwards linear;
        -moz-animation: sliderbg 5s forwards linear;
        -ms-animation: sliderbg 5s forwards linear;
        animation: sliderbg 5s forwards linear;
    }
    @-webkit-keyframes sliderbg {
        0% { background-position: 0 0; }
        100% { background-position: 100% 0; }
    }
    @-moz-keyframes sliderbg {
        0% { background-position: 0 0; }
        100% { background-position: 100% 0; }
    }
    @-ms-keyframes sliderbg {
        0% { background-position: 0 0; }
        100% { background-position: 100% 0; }
    }
    @keyframes sliderbg {
        0% { background-position: 0 0; }
        100% { background-position: 100% 0; }
    }
    ul#partners-list li {
        width: 50%;
        width: calc(100% / 2);
    }
    ul#partners-list li a span { height: 48px; }
    #catalog-form {
        padding: 6px 0 60px;
    }
    #catalog-form .form-grid .container .group {
        width: calc(100% / 1 - (1 * 0px));
    }
    #catalog-form .form-grid .container .cell-group:nth-child(2) { float: left; }
    #catalog-form .form-grid .container .cell-group {
        width: 100%;
        width: calc(100% / 1 - (1 * 0px));
    }
    #catalog-form .form-grid .container .group:first-child { margin-top: 20px; }
    #catalog-form .form-grid .container .group-text .important { text-align: center; }
    #modal-box .info-text p { padding: 0 30px; }
    .cell-box fieldset.active .group.large,
    .cell-box fieldset.active .group.small {
        width: 100%;
        width: calc(100% / 1 - (1 * 42px));
        max-width: 368px;
        margin: 10px auto 0;
        display: block;
    }
    .important-value { text-align: center; }
    .cell-box .price-box .about-project .info {
        width: 100%;
        height: 100%;
        display: table;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
     }
    .cell-box .price-box .about-project .info .vertical {
        width: 100%;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
    .cell-box .price-box .about-project .info .vertical span.name {
        padding-top: 30px;
    }

}
@media screen and (max-width: 380px) {
    header.mobile {
        -webkit-box-shadow: 0 3px 5px rgba(0,0,0,0.147);
        -moz-box-shadow: 0 3px 5px rgba(0,0,0,0.147);
        box-shadow: 0 3px 5px rgba(0,0,0,0.147);
    }
    header .header-container { padding: 30px 30px 0px !important; }
    header { padding-bottom: 30px !important; }
    section#two-columns .grid-row .grid-box .container {
        padding: 3px 0 0 60px;
    }
    section#two-columns .grid-row .grid-box .container:before {
        width: 40px;
        height: 40px;
        background-size: 20px;
    }
    #banner-about-us article p {
        padding: 40px;
        font-size: 24px;
        line-height: 36px;
    }
    section#about-us.contact { margin-top: 92px; }
}
@media screen and (max-width: 350px) {
    #video-trns .apex-title .container h1 {
        font-size: 40px;
        line-height: 50px;
    }
    #video-trns .apex-title .container a { font-size: 17px; }
    .text-container .box {
        padding: 0 20px;
    }
    .text-container:nth-child(1) .box { padding: 0 20px; }
    .text-container:nth-child(2) .box{ padding: 0 20px; }
    #we-are .profile-box .container .img-profile {
        width: 240px;
        height: 240px;
    }
    ul#partners-list li {
        width: 100%;
        width: calc(100% / 1);
    }
    #banner-about-us.contact article.email-mobile p { font-size: 26px; }
    .cell-box .price-box .about-project .info .vertical span.name {
        padding-top: 10px;
    }

    ul#project-list li a.project-banner .row .content span.medium-link {bottom: 50px}
}
@media screen and (max-height: 770px) and (orientation: landscape)  {
    #video-trns .animation {
        margin-left: 0px;
    }
    .scroll-fix .price-box {
        height: 250px;
    }
    .scroll-fix .second-box p {
        padding: 40px 50px;
    }
}
@media screen and (max-height: 580px) {
    .scroll-fix.scroll-to-fixed-fixed {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
    }
    .fix { display: none !important; }
    .scroll-fix.scroll-to-fixed-fixed .price-box {
        height: auto !important;
    }
     
}
@media screen and (min-width: 950px) {
    #video-trns .animation {
        background: url("../images/icon-trans.gif") no-repeat 0 50%;
        background-size: auto 100%;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    
}

@-moz-document url-prefix() { 
    
}






.typeahead,
.tt-query,
.tt-hint {
  width: 100%;
  height: 30px;
  padding: 8px 12px;
  font-size: 24px;
  line-height: 30px;
  outline: none;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  
}

.tt-hint {
  color: #999
}
.twitter-typeahead { width: 100%; }

.tt-menu {
  width: 100%;
  margin-top: 19px;
  padding: 25px 0;
  background-color: #fff;
  -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.201);
     -moz-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.201);
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.201);
}

.tt-suggestion {
    font-family: "PT Serif";
  padding: 14px 40px;
  font-size: 16px;
  color: #eb1d71;
}
.twitter-typeahead input:first-letter {
    text-transform: uppercase;
}
.tt-suggestion strong { font-weight: normal; }

.tt-suggestion:hover {
  cursor: pointer;
  color: #21252b;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;

}

.tt-suggestion span {
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(235,29,113,.853);
}
.tt-suggestion:hover span { padding-bottom: 1px; }

.tt-suggestion p {
  margin: 0;
}

.gist {
  font-size: 14px;
}

/* example specific styles */
/* ----------------------- */

#custom-templates .empty-message {
  padding: 5px 10px;
 text-align: center;
}

#multiple-datasets .league-name {
  margin: 0 20px 5px 20px;
  padding: 3px 0;
  border-bottom: 1px solid #ccc;
}

#scrollable-dropdown-menu .tt-menu {
  max-height: 150px;
  overflow-y: auto;
}

#rtl-support .tt-menu {
  text-align: right;
}








