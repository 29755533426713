.cell-group > .ok-label,
.law-info > .ok-label {
    width: 100%;
    width: calc(100% / 1 - (1 * 40px));
    max-width: 370px;
    float: left;
    margin: 20px auto 0;
    position: relative;
    text-align: left; 
}

.law-info > .ok-label {
    max-width: 100%;

    &.invalid label {
        -webkit-box-shadow: 0 0 9px rgba(235,29,111,0.5);
        -moz-box-shadow: 0 0 9px rgba(235,29,111,0.5);
        box-shadow: 0 0 9px rgba(235,29,111,0.5);
    }
}

.cell-group > .ok-label input,
.law-info > .ok-label input {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.cell-group > .ok-label label,
.law-info > .ok-label label {
    width: 26px;
    height: 26px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border: 2px solid #ccc;
    -webkit-transition: all 0.14s linear;
    -moz-transition: all 0.14s linear;
    -o-transition: all 0.14s linear;
    -ms-transition: all 0.14s linear;
    transition: all 0.14s linear;
}
.cell-group > .ok-label label:before,
.cell-group > .ok-label label:after,
.law-info > .ok-label label:before,
.law-info > .ok-label label:after {
    width: 16px;
    height: 2px;
    content: "";
    position: absolute;
    top: 12px;
    left: 5px;
    background: #ccc;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.14s linear;
    -moz-transition: all 0.14s linear;
    -o-transition: all 0.14s linear;
    -ms-transition: all 0.14s linear;
    transition: all 0.14s linear;
}
.cell-group > .ok-label label:before,
.law-info > .ok-label label:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}
.cell-group > .ok-label label:after,
.law-info > .ok-label label:after {
    -webkit-transform: rotate(-48deg);
    -moz-transform: rotate(-48deg);
    transform: rotate(-48deg);
}
.cell-group > .ok-label input:checked ~ label,
.law-info > .ok-label input:checked ~ label {
    border: 2px solid #eb1d6f;
}
.cell-group > .ok-label input:checked ~ label:before,
.law-info > .ok-label input:checked ~ label:before {
    width: 7px;
    top: 14px;
    left: 6px;
    background: #eb1d6f;
    -webkit-transform: rotate(-140deg);
    -moz-transform: rotate(-140deg);
    transform: rotate(-140deg);
}
.cell-group > .ok-label input:checked ~ label:after,
.law-info > .ok-label input:checked ~ label:after {
    width: 12px;
    left: 10px;
    background: #eb1d6f;
    -webkit-transform: rotate(-225deg);
    -moz-transform: rotate(-225deg);
    transform: rotate(-225deg);
}
.cell-group > .ok-label span,
.law-info > .ok-label span {
    display: inline-block;
    margin-top: 6px;
    padding-left: 42px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;

    a {
        display: inline-block;
        vertical-align: top;
        position: relative;
        font-size: 12px;
        text-transform: uppercase;
        color: #eb1d71;
        user-select: none;
        transition: color 0.22s ease;

        &:hover {
            color: #21252b;
            text-decoration: none;
        }
    }
}