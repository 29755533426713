#home-three {
    margin-top: 60px;
    // padding-bottom: 70px;

    @media #{$tablet} {
        margin-top: 0;
    }

    >ul {
        width: 100%;
        float: left;
        position: relative;

        >li {
            width: 33.3333%;
            width: calc(100% / 3);
            height: 320px;
            float: left;
            position: relative;
            overflow: hidden;
            @include transition(all 0.24s ease);

            &:hover {
                @include box-shadow(0, 20px, 40px, 0 rgba(0,0,0,0.15));

                .long-arrow {
                    width: 70px;
                }
            }
           
            &:nth-of-type(1) {
                width: 50%;
                background-color: $pink;

                @media #{$tablet1} {
                    width: 100%;
                }

                @media #{$tablet} {
                    height: 640px;
                }

                a {
                    color: $white;

                    .sct-three p {
                        color: $white;
                        opacity: 0.7;
                    }

                    >div {
                        width: 50%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        overflow: hidden;

                        @media #{$tablet} {
                            width: 100%;
                            height: 50%;
                        }

                        &.img {
                            left: 0;
                            background-position: center;
                            background-repeat: no-repeat;
                            @include cover;
                        }

                        &.title {
                            right: 0;

                            @media #{$tablet} {
                                top: auto;
                                bottom: 0;
                            }
                        }
                    }
                }
            }

            &:nth-of-type(2),
            &:nth-of-type(3) {
                width: 25%;

                @media #{$tablet1} {
                    width: 50%;
                }

                @media #{$tablet} {
                    width: 100%;
                }
            }

            >a {
                @include absolute;
                color: $black;
                text-decoration: none;
                @include transition(all 0.24s ease);
            }

            .sct-three {
                position: absolute;
                top: 50px;
                left: 50px;
                right: 50px;
                bottom: 50px;

                >h5 {
                    font-size: 30px;
                    line-height: 40px;
                    letter-spacing: -0.25px;
                }

                >p {
                    margin-top: 24px;
                    font-size: 14px;
                    line-height: 24px;
                    color: #888888;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}