/* colors */
$pink: #eb1d71;
$white: #FFF;
$black: #21252b;
$grey: #eaeaea;
/* fonts */
$serif: 'PT Serif';
$regular: 'Roboto';
$helvetica: 'Helvetica';
/* responsive width */
$full1: "screen and (max-width: 2000px)";
$full: "screen and (max-width: 1800px)";
$desktop5: "screen and (max-width: 1600px)";
$desktop7: "screen and (max-width: 1450px)";
$desktop6: "screen and (max-width: 1380px)";
$desktop4: "screen and (max-width: 1300px)";
$desktop3: "screen and (max-width: 1220px)";
$desktop2: "screen and (max-width: 1170px)";
$desktop1: "screen and (max-width: 1100px)";
$desktop: "screen and (max-width: 1200px)";
$tablet5: "screen and (max-width: 1030px)";
$tablet4: "screen and (max-width: 1024px)";
$tablet3: "screen and (max-width: 1080px)";
$tablet2: "screen and (max-width: 1000px)";
$tablet1: "screen and (max-width: 900px)";
$tablet: "screen and (max-width: 770px)";
$mobil3: "screen and (max-width: 800px)";
$mobil2: "screen and (max-width: 660px)";
$mobil1: "screen and (max-width: 600px)";
$mobil: "screen and (max-width: 480px)";
$mobil6: "screen and (max-width: 380px)";
$mobil5: "screen and (max-width: 370px)";
$mobil7: "screen and (max-width: 350px)";
/* min */
$min1: "screen and (min-width: 1000px)";
$min5: "screen and (min-width: 10px)";
$hover: "screen and (min-width: 1024px)";
$header1: "screen and (min-width: 600px)";
// landscape
$landscape: "screen and (orientation:landscape)";
$portrait: "screen and (orientation:portrait)";
//height
$height: "screen and (max-height: 630px) and (max-width: 1000px)";
/* padding */
$desktopPadding: 60px;
$tabletPadding: 40px;
$mobilPadding: 0;


@mixin hover {
   
    @at-root {
        @-moz-document url-prefix() {
            &:hover {
                @content;
            }
        }
    }

    @supports (-ms-ime-align:auto) {
        & {
            &:hover {
                @content;
            }
        }
    }

    @media screen\0 {
        &:hover {
            @content;
        }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        &:hover {
            @content;
        }
    }

    
    @media not all and (hover: none) {
        &:hover {
            @content;
        }
    }
}

/* fixed */
@mixin fixed {
    position: fixed;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
/* absolute */
@mixin absolute {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
}
@mixin absolute2 {
    position: absolute;
    top: 0;
    z-index: 4;
    overflow: hidden;
}
@mixin cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

/* pseudo */
@mixin pseudo {
    content: "";
    position: absolute;
    overflow: hidden;
}
/* backface */
@mixin backface {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
@mixin border-box {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}
@mixin content-box {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
@mixin bugs {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    perspective: 1000;
}

/* radius */
@mixin border-radius {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

@mixin border-radius-button {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

@mixin border-radius2 {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

@mixin border-radius-small {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

@mixin border-radius-small2 {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

@mixin border-radius-none {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

@mixin border-radius3($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}
// @include border-radius(top left, top right, bottom right, bottom left);

/* select */
@mixin noselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@mixin noselectyes {
    -webkit-user-select: auto;
    -moz-user-select: auto;
    user-select: auto;
}


/* transitions */
@mixin transition($transition...) {
    -ms-transition:    $transition;
    -moz-transition:    $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
@mixin transition-property($property...) {
    -ms-transition-property:    $property;
    -moz-transition-property:    $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition-duration($duration...) {
    -ms-transition-property:    $duration;
    -moz-transition-property:    $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
    -ms-transition-timing-function:    $timing;
    -moz-transition-timing-function:    $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
    -ms-transition-delay:    $delay;
    -moz-transition-delay:    $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

/* transform */
$center: 50% 50%;
@mixin transform-origin($center) {
    -webkit-transform-origin: $center;
    -moz-transform-origin: $center;
    transform-origin: $center;
}

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin scale($scale) {
    @include transform(scale($scale));
}

@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

@mixin centerer {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
 

/* box shadow */
@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
    -webkit-box-shadow:$top $left $blur $color #{$inset};
    -moz-box-shadow:$top $left $blur $color #{$inset};
    box-shadow:$top $left $blur $color #{$inset};
}


/* gradient */
@mixin linearGradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
}

@mixin horizontalGradient($left, $right){
    background: $left; /* Old browsers */
    background: -moz-linear-gradient(right,  $left 0%, $right 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left right, left right, color-stop(0%,$left), color-stop(100%,$right)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(right,  $left 0%,$right 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(right,  $left 0%,$right 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(right,  $left 0%,$right 100%); /* IE10+ */
    background: linear-gradient(right left,  $left 0%,$right 100%); /* W3C */
}

