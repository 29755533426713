#newsletter {
	width: 100%;
	float: left;
	position: relative;
	margin-top: 100px;
	box-sizing: border-box;
	border: 4px solid $pink;

	@media #{$tablet} {
        margin-top: 50px;
    }

	.table-form {
		width: 100%;
		float: left;
		position: relative;
		text-align: center;
		padding: 66px 0;

		>li {
			display: block;
			position: relative;

			&.title-newsletter {
				float: left;
				margin-left: 90px;
				top: 16px;
				font-family: $serif;
				font-size: 22px;

				@media #{$desktop2} {
			        margin-left: 40px;
			    }

			    @media #{$tablet} {
			        width: 100%;
			        float: none;
			        top: 0;
			        margin: 0;
			    }
			}

			&.input-item {
				width: 100%;
				max-width: 430px;
				display: inline-block;
				vertical-align: middle;

				@media #{$tablet1} {
			        max-width: 300px;
			    }

			    @media #{$tablet} {
			        max-width: 400px;
			        display: block;
			        margin: 0 auto;
			        padding-top: 40px;
			    }

			    @media #{$mobil} {
			        max-width: 95%;
			        max-width: calc(100% - 22px);
			        margin: 0 10px;
			    }

				.group {
				    width: 100%;
				    height: 50px;
				    text-align: left !important;
				    position:relative;
				    padding: 7px 0 0;
				    font-size: 0;
				    -webkit-transition: all 0.12s;
				    -moz-transition: all 0.12s;
				    -o-transition: all 0.12s;
				    -ms-transition: all 0.12s;
				    transition: all 0.12s;
				    border: 1px solid #eaeaea;
				    border-bottom: 2px solid #eb1d71;

				    &.valid,
				    &.invalid {

				    	label {
				    		top: 0!important;
				    	}
				    }

				    input {
					    width: 100%;
					    width: calc(100% / 1 - (1 * 40px));
					    padding: 10px 20px 3px;
					    display:block;
					    position: relative;
					    font-family: "PT Serif";
					    font-size: 20px;
					    line-height: 1;
					    letter-spacing: -0.025em;
					    color: #888;
					    -webkit-text-fill-color: #888 !important;
					    border:none;
					    background: transparent;
					    z-index: 3;

					    &:checked ~ label {
						    border: 2px solid #eb1d6f;
						}

						&:focus { outline:none; }
					}

					label { 
					    position:absolute;
					    top: 23px;
					    left: 20px;
					    font-size:12px;
					    line-height: 1;
					    z-index: 1;
					    text-transform: uppercase;
					    transition: all 0.26s ease;
					    pointer-events:none;
					}
				}
			}

			&.btn-newsletter {
				float: right;
				margin-right: 110px;
				top: 18px;

				@media #{$desktop2} {
			        margin-right: 40px;
			    }

			    @media #{$tablet} {
			        width: 100%;
			        float: none;
			        top: 0;
			        margin: 0;
			        padding-top: 40px;
			    }

				button {
				    display: inline-block;
				    position: relative;
				    padding-bottom: 8px;
				    margin: 0 auto;
				    font-family: 'PT Serif', serif;
				    font-size: 16px;
				    line-height: 1;
				    color: #eb1d71;
				    text-decoration: none;
				    background: transparent;
				    border: 0;
				    transition: all 0.24s ease;

				    &:focus { outline: none; }

					&:before {
					    width: 100%;
					    height: 1px;
					    content: "";
					    position: absolute;
					    bottom: 0;
					    left: 0;
					    background: rgba(235,29,113,0.853);
					    transition: all 0.24s ease;
					}

					&:hover {
						color: $black;

						&:before {
						    bottom: 3px;
						}
					}
				}
			}
		}
	}
}