.search-count {
	width: 100%;
	display: block;
	position: relative;
	text-align: left;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s ease-out;

	@media #{$desktop3} {
        text-align: center;
    }

	&.results {
		opacity: 1;
		visibility: visible;
	}

	.cnt-itm {
		display: inline-block;
		vertical-align: top;
		margin: 58px 0 0 38px;
		font-size: 12px;
		text-transform: uppercase;
		color: #888;
		user-select: none;

		@media #{$desktop3} {
	        margin: 58px auto 0;
	    }

		>span {
			height: 24px;
			display: inline-block;
			vertical-align: top;
			position: relative;
			top: -5px;
			margin-left: 7px;
			padding: 0 10px 0 9px;
			color: $pink;
			font-weight: bold;
			font-size: 12px;
			line-height: 25px;
			letter-spacing: -0.5px;
			border-radius: 20px;
			background-color:  rgba(235, 29, 113, 0.1);
		}
	}
}
ul#apex-projects {
	width: 100%;
	display: block;
	float: left;
	position: relative;
	font-size: 0;
	margin-top: 34px;

	>li {
		width: 23.3%;
		width: calc(25% - 30px);
		display: inline-block;
		vertical-align: top;
		position: relative;
		margin: 15px;
		background-color: $white;

		@media #{$desktop6} {
	        width: 31%;
			width: calc(100% / 3 - 30px);
	    }

	    @media #{$tablet2} {
	        width: 49%;
			width: calc(100% / 2 - 30px);
	    }

	    @media #{$mobil2} {
	        width: 100%;
	        margin: 15px 0;
	    }

		&:not(.category-list):not(.project-banner):hover {

			span.long-arrow {
				width: 155px;
			}

			img.preload-zoom {
			    -webkit-transform: scale(1.08, 1.08);
			    -moz-transform: scale(1.08, 1.08);
			    -o-transform: scale(1.08, 1.08);
			    -ms-transform: scale(1.08, 1.08);
			    transform: scale(1.08, 1.08);
			    -webkit-transition: 5s ease-out;
			    -moz-transition: 5s ease-out;
			    -ms-transition: 5s ease-out;
			    -o-transition: 5s ease-out;
			    transition: 5s ease-out;
			}
		}

		&.project-empty {
			width: 100%;
			margin: 0;
			text-align: center;
			font-family: $serif;
			font-size: 22px;
			display: none;
		}

		&.category-list {
			background-color: $pink;

			.cnt-cat {
				width: 100%;
				display: block;
				position: relative;

				@media #{$mobil2} {
			        height: 200px!important;
			    }

				&:before {
					width: 15px;
					height: 40px;
					@include pseudo;
					top: 50%;
					right: -15px;
					margin-top: -20px;
					background: url(../images/category-arrow.svg) no-repeat 50%;

					@media #{$mobil2} {
				        top: auto;
				        bottom: -27px;
				        right: auto;
				        left: 50%;
				        margin: 0 0 0 -8px;
				        transform: rotate(90deg);
				    }
				}

				>span {
					display: inline-block;
					vertical-align: middle;
					position: absolute;
					top: 50%;
					left: 50%;
					color: $white;
					font-family: $serif;
					font-size: 40px;
					letter-spacing: -0.02em;
					transform: translate(-50%, -50%);
					z-index: 1;
					margin-top: -6px;
				}
			}
		}

		&.project-banner {
			background-color: $white;

			&:hover {
				
				span.long-arrow {
					width: 70px;
				}
			}

			a.cnt-cat {
				width: 100%;
				display: block;
				position: relative;
				overflow: hidden;
				color: $black;
				text-decoration: none;
				box-shadow: 0 4px 16px 0 rgba(0,0,0,0.1);
				transition: all 0.24s ease;

				@media #{$mobil2} {
			        height: 350px!important;
			    }

				&:hover {
					z-index: 10;
					box-shadow: 0 0 0 0 rgba(0,0,0,0);
					background-color: $pink;

					h2 {
						color: $white;
					}

					span.read-more {
						color: $white;

						&:before {
							bottom: 3px;
							background: rgba(255,255,255,.643);
						}
					}
				}

				>h2 {
					position: relative;
				    padding: 68px 38px 0;
				    font-family: $serif;
				    font-size: 30px;
				    font-weight: normal;
				    line-height: 40px;
				    letter-spacing: -0.025em;
				    overflow: hidden;
				    color: $black;
				    transition: all 0.24s ease;
				}

				span.read-more {
				    display: inline-block;
				    padding-bottom: 6px;
				    position: absolute;
				    font-family: $serif;
				    font-size: 16px;
				    color: $pink;
				    text-decoration: none;
					left: 38px;
					bottom: 70px;
					z-index: 1;
					transition: all .24s ease;

					&:before {
						height: 1px;
					    width: 100%;
					    @include pseudo;
					    bottom: 0;
					    left: 0;
					    background: rgba(235,29,113,.853);
					    transition: all .24s ease;
					}
				}
			}
		}

		>a {
			width: 100%;
			display: block;
			position: relative;
			overflow: hidden;
			color: $black;
			text-decoration: none;
			box-shadow: 0 4px 16px 0 rgba(0,0,0,0.1);
			transition: all 0.24s ease;

			&:hover {
				z-index: 10;
				box-shadow: 0 25px 80px 0 rgba(0,0,0,0.20), 0 0 15px 0 rgba(0,0,0,0.06);
			}

			>.img-box {
				width: 100%;
			    display: block;
			    position: relative;
			    overflow: hidden;

				>img {
					margin: 0;
					width: 100%;
					max-width: 100%;
				    min-width: 100%;
					box-sizing: border-box;
					-webkit-backface-visibility: hidden;
					backface-visibility: hidden;

				    &.blured {
				    	position: absolute;
				    	top: 0;
				    	left: 0;
					    -webkit-filter: blur(5px);
					    -moz-filter: blur(5px);
					    -o-filter: blur(5px);
					    -ms-filter: blur(5px);
					    filter: blur(5px);
					    -webkit-transform: scale(1.4);
					    -moz-transform: scale(1.4);
					    -o-transform: scale(1.4);
					    -ms-transform: scale(1.4);
					    transform: scale(1.4);
					    z-index: 1;
				    }
				}

				img.lazyloaded ~ img.blured {
				    display: none !important;
				}
			}

			>.info-box {
				width: 100%;
				display: block;
				float: left;
				padding: 32px 0 37px;

				@media #{$mobil2} {
			        padding: 27px 0 32px;
			    }

				>.title {
					width: 100%;
					display: block;
					position: relative;
					z-index: 4;

					h2 {
						max-width: 180px;
						display: inline-block;
						vertical-align: top;
						position: relative;
						padding: 0 10px 0 37px;
						font-size: 20px;
						font-weight: normal;
						color: $black;
						overflow: hidden;
						background-color: $white;
						z-index: 2;

						@media #{$mobil2} {
					        padding: 0 10px 0 20px;
					    }

						>span {
							display: -webkit-box;
						    -webkit-line-clamp: 1;
						    -webkit-box-orient: vertical;
						    overflow: hidden;
						    text-overflow: ellipsis;
						}
					}

					span.long-arrow {
						top: 10px;
						left: auto;
						right: 35px;
						bottom: auto;
						z-index: 1;

						@media #{$mobil2} {
					        right: 20px;
					    }
					}
				}

				.inf-row {
					width: 100%;
					display: block;
					float: left;
					position: relative;
					margin-top: 5px;

					&:nth-of-type(2) {
						margin-top: 18px;
					}

					>span {
						font-size: 12px;

						&.title {
							float: left;
							padding: 0 0 0 37px;
							color: #888;

							@media #{$mobil2} {
						        padding: 0 0 0 20px;
						    }
						}

						&.number {
							float: right;
							padding: 0 37px 0 0;

							@media #{$mobil2} {
						        padding: 0 20px 0 0;
						    }
						}
					}
				}
			}
		}
	}
}

.btn-next-projects {
	width: 100%;
	height: 60px;
	float: left;
	position: relative;
	text-align: center;
	font-size: 0;
	margin-top: 85px;
	user-select: none;

	>button {
		height: 60px;
		display: inline-block;
		vertical-align: top;
		padding: 0 29px;
		margin: 0 auto;
		background-color: $pink;
		color: $white;
		font-size: 14px;
		border-radius: 0;
		border: none;
		outline: none;
		text-transform: uppercase;
		cursor: pointer;
		transition: all .22s ease-out;

		&:hover {
			background-color: $black;
		}
	}
}






