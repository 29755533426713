/* footer */
footer {
    width: 100%;
    float: left;
    position: relative;
    overflow: hidden;
}
footer#page-partners #general-footer { border-top: 0; }
#general-footer {
    width: 100%;
    float: left;
    padding: 70px 0 100px;
    border-top: 1px solid #eaeaea;
}
#general-footer .social-networks {
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0 0 70px;
    text-align: center;
    border-bottom: 1px solid #eaeaea;
    font-size: 0;

    a {
        text-decoration: none;

        &.ntw {
            border: 1px solid #eaeaea;
            margin: 0 10px;

            @media #{$mobil3} {
                margin-top: 35px;
            }

            @media #{$mobil6} {
                margin: 35px 5px 0;
            }
        }

        &:nth-of-type(1) {
            float: left;
            margin-left: 135px;

            @media #{$tablet2} {
                margin-left: 20px;
            }

            @media #{$mobil3} {
                margin: 0 auto;
                float: none;
                display: table!important;
                clear: both;
            }
        }

        &:nth-of-type(2) {
            float: right;
            margin-right: 135px;

            @media #{$tablet2} {
                margin-right: 20px;
            }

            @media #{$mobil3} {
                margin: 20px auto 0;
                float: none;
                display: table!important;
                clear: both;
            }
        }
        &:nth-of-type(1),
        &:nth-of-type(2) {
            display: inline-block;
            position: relative;
            top: 14px;
            padding-bottom: 6px;
            font-family: 'PT Serif', serif;
            font-size: 22px;
            color: #eb1d71;
            cursor: pointer;
            @include transition(all 0.24s ease);

            @media #{$mobil3} {
                top: 0;
            }

            &:before {
                width: 100%;
                height: 1px;
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                background: rgba(235,29,113,0.853);
                @include transition(all 0.24s ease);
            }

            &:hover {
                color: $black;

                &:before {
                    bottom: 3px;
                }
            }
        }
    }
}
#general-footer .footer-nav {
    width: 100%;
    display: block;
    margin-top: 100px;
    font-size: 0;
    text-align: center;

    @media #{$mobil2} {
        display: none;
    }
}
#general-footer .footer-nav a {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0 15px 10px;
    font-size: 12px;
    text-transform: uppercase;
    color: #eb1d71;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    &:last-child {
        padding-left: 62px;
        margin: 0 0 10px 15px;

        img {
            width: 24px;
            position: absolute;
            top: -4px;
            left: 30px;
        }

        &:before {
            width: 1px;
            height: 40px;
            @include pseudo;
            top: -10px;
            left: 0;
            background: #eaeaea;
        }
    }
}
#general-footer .footer-nav a.active { color: #21252b;text-decoration: none; }
#general-footer .copyright {
    width: 100%;
    display: block;
    margin-top: 30px;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #888888;

    @media #{$mobil2} {
        margin-top: 70px;
    }
}
#general-footer .copyright a {
    display: inline-block;
    margin-left: 30px;
    color: #eb1d71;

    @media #{$mobil1} {
        margin: 40px auto 0 auto;
    }
}
