.bill-info {
	width: 100%;
	float: left;
	position: relative;
	margin-top: 50px;

	>span {
	    width: 100%;
	    display: block;
	    margin: 0 auto 16px;
	    text-align: left;
	    font-family: $serif;
	    line-height: 1;
	}
}

.law-info {
	width: 100%;
	float: left;
	position: relative;
}