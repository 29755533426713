section#home-catalog {

	.catalog-box {
		margin-top: 100px;

		.box-item {
			width: 50%;
			height: 400px;
			float: left;
			position: relative;

			@media screen and (max-width: 768px) {
				width: 100%;
				height: auto;
			}

			&.img-item {
				background-repeat: no-repeat;
				background-position: 50%;
				background-size: cover;

				@media screen and (max-width: 768px) {

					&:before {
						content: "";
						display: block;
						padding-top: 100%;
					}
				}
			}

			&.text-item {

				&:before {
					@include absolute;
					@include pseudo;
					box-sizing: border-box;
					border-top: 4px solid $pink;
					border-right: 4px solid $pink;
					border-bottom: 4px solid $pink;
					pointer-events: none;

					@media screen and (max-width: 768px) {
						border-top: 0;
						border-left: 4px solid $pink;
					}
				}

				>.container {
					width: 100%;
					height: 100%;
					display: table;
					text-align: center;

					.text-article {
						display: table-cell;
						vertical-align: middle;
						
						>.article {
							max-width: 380px;
							display: inline-block;
							vertical-align: top;
							margin: 0 auto;
							padding: 40px;
							text-align: left;

							@media screen and (max-width: 768px) {
								max-width: 100%;
								padding: 40px 30px 50px;
								text-align: center;
							}
						
							h2 {
								font-size: 30px;
							    line-height: 40px;
							    letter-spacing: -.25px;
							}

							p {
								margin-top: 15px;
								font-size: 16px;
							    font-weight: 400;
							    line-height: 30px;
							    color: #888;
							}

							a {
								margin-top: 15px;
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}
}