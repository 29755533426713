nav {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background: rgba(229, 0, 93, 0.95);
    overflow: hidden;
    -webkit-transform: translateZ(0);
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    -webkit-transition: opacity 0.3s, visibility 0.3s;
    -moz-transition: opacity 0.3s, visibility 0.3s;
    transition: opacity 0.3s, visibility 0.3s;
}
nav.visible {
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    -webkit-transition: opacity 0.3s, visibility 0.3s;
    -moz-transition: opacity 0.3s, visibility 0.3s;
    transition: opacity 0.3s, visibility 0.3s;
}
nav .nav-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -moz-osx-font-smoothing: grayscale;
}
nav.visible .nav-container {
    -webkit-overflow-scrolling: touch;
}
#general-nav-large {
    width: 100%;
    position: relative;
    top: 50%;
    left: 0;
    z-index: 10;
    -webkit-transform: translate(0,-50%);
    -moz-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    -o-transform: translate(0,-50%);
    transform: translate(0,-50%);

    @media #{$tablet2} {
        top: 0;
        -webkit-transform: translate(0,0);
        -moz-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        transform: translate(0,0);
        padding: 140px 0 0;
    }
}
#general-nav-large .nav-grid {
    position: relative;
    padding: 0 40px;
}
#general-nav-large .nav-grid .container {
    width: 100%;
    max-width: 1170px;
    display: block;
    position: relative;
    margin: 0 auto;
}
#large-nav {
    width: 100%;
    display: table;
    margin: 0 auto;
    text-align: center;

    @media #{$tablet2} {
        display: block;
    }
}

a.lg-page {
    display: block;
    @include fixed;
    top: 83px;
    right: 190px;
    padding: 30px 30px 30px 33px;
    margin: 0 0 10px 15px;
    z-index: 99;
    color: $white;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    @include transition(all 0.24s ease);

    @media #{$tablet1} {
        top: 7px;
    }

    @media #{$mobil2} {
        right: auto;
        left: 20px;
    }

    &.mobile {
        top: 7px;
    }

    &:hover {

        span:before {
            bottom: 0;
        }
    }

    span {
        position: relative;

        &:before {
            width: 100%;
            height: 1px;
            @include pseudo;
            bottom: -2px;
            left: 0;
            background: rgba(255,255,255,0.45);
            @include transition(all 0.24s ease);
        }
    }

    img {
        width: 24px;
        position: absolute;
        top: 27px;
        left: 0;
    }
}

.nav-text {
    max-width: 370px;
    float: left;
    margin-left: 80px;
    padding-right: 60px;
    text-align: left;
    border-right: 1px solid rgba(0,0,0,0.15);
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    -webkit-transition: opacity 0.1s, visibility 0.1s;
    -moz-transition: opacity 0.1s, visibility 0.1s;
    transition: opacity 0.1s, visibility 0.1s;

    @media #{$desktop3} {
        max-width: 290px;
        margin-left: 30px;
        padding-right: 30px;
    }

    @media #{$tablet2} {
        display: none;
    }

    &.load {
        opacity: 1;
        filter: alpha(opacity=100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        visibility: visible;
        -webkit-transition: opacity 0.3s, visibility 0.3s;
        -moz-transition: opacity 0.3s, visibility 0.3s;
        transition: opacity 0.3s, visibility 0.3s;
    }

    >article {
        padding: 40px 0 20px;

        >p {
            font-size: 22px;
            line-height: 38px;
            font-weight: 300;
            color: $white;

            @media #{$desktop3} {
                font-size: 22px;
                line-height: 36px;
            }

            a {
                color: $white;
                text-decoration: none;
            }
        }
    }
}

#large-nav .section {
    display: block;
    float: left;
    vertical-align: top;
    margin: 0 auto 0 0;

    @media #{$tablet2} {
       width: 100%;
    }
}
#large-nav .section .link {
    display: block;
    text-align: left;
    padding: 15px 0;

    @media #{$tablet2} {
       text-align: center;
    }
}
#large-nav .section a {
    display: inline-block;
    position: relative;
    padding-bottom: 8px;
    top: -50px;
    left: 0;
    font-family: 'PT Serif', serif;
    font-size: 22px;
    line-height: 1;
    text-decoration: none;
    text-align: left;
    color: #fff;
    opacity: 0;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
#large-nav .section a:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(255,255,255,0.4);
}
#large-nav .section a.active:before { bottom: 3px;background: rgba(255,255,255,0.9); }
#large-nav .section a.visible {
    opacity: 1;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    top: 0;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}
#nav-footer {
    top: 50%;
    -webkit-transform: translateY(62%);
    transform: translateY(62%);
    position: relative;
    float: left;
    opacity: 0;
    padding-left: 70px;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    -webkit-transition: opacity 0.1s, visibility 0.1s;
    -moz-transition: opacity 0.1s, visibility 0.1s;
    transition: opacity 0.1s, visibility 0.1s;

    @media #{$desktop3} {
        padding-left: 40px;
    }

    @media #{$tablet2} {
        width: 100%;
        text-align: center;
        top: 0;
        padding: 70px 0 140px 0;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
#nav-footer.load {
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    visibility: visible;
    -webkit-transition: opacity 0.3s, visibility 0.3s;
    -moz-transition: opacity 0.3s, visibility 0.3s;
    transition: opacity 0.3s, visibility 0.3s;
}
#nav-footer .adress {
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 1;
    color: #fff;
    letter-spacing: -0.025em;
    text-align: left;

    @media #{$tablet2} {
       text-align: center;
    }
}
#nav-footer .adress .texts {
    display: block;

    &:nth-of-type(1) {
        max-width: 240px;
        line-height: 1.4;

        @media #{$tablet2} {
            max-width: 100%;
        }
    }

    &:nth-of-type(2) {
        margin-top: 36px;
    }
}
 
#nav-footer .adress a {
    display: table;
    position: relative;
    color: #fff;
    text-decoration:none;
    font-size:22px;
    font-family: $serif;
    margin-top: 28px;

    @media #{$tablet2} {
        margin-left: auto;
        margin-right: auto;
    }
}
#nav-footer .adress a span {
    position: relative;
    padding-bottom: 5px;
}
#nav-footer .adress a span:before {
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(255,255,255,0.4);
}
#nav-footer .socials {
    width: 100%;
    float: left;
    display: block;
    position: relative;
    margin-top:44px;
    font-size: 0;
    text-align: left;

    @media #{$tablet2} {
        text-align: center;
    }
}
a.ntw {
    width: 58px;
    height: 58px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    border: 1px solid #f377aa;
    @include border-radius;

    &:not(:first-child) {
        margin-left: 20px;

        @media #{$mobil6} {
            margin-left: 10px;
        }
}

    // &:hover {
    //     border: 1px solid $white;
    //     transition: all 0.75s cubic-bezier(.19,1,.22,1);
    // }
}
a.ntw:before {
    width: 200%;
    height: 200%;
    margin: -50% 0 0 -50%;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    -moz-transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    -ms-transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    -o-transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    -webkit-transform: scale3d(.1,.1,.1);
    -moz-transform: scale3d(.1,.1,.1);
    -ms-transform: scale3d(.1,.1,.1);
    -o-transform: scale3d(.1,.1,.1);
    transform: scale3d(.1,.1,.1);
    opacity: 0;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
}
.social-networks a.ntw:before { background: #eb1d71; }
.socials a.ntw:before { background: #fff; }
a.ntw:after {
    width: 58px;
    height: 58px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    -webkit-transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    -moz-transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    -ms-transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    -o-transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    transition: transform .75s cubic-bezier(.19,1,.22,1),opacity .6s cubic-bezier(.19,1,.22,1);
    opacity: 0;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
}
a.link-facebook {
    background: url("../images/icon-fb.png") no-repeat 50%;
    background: url("../images/icon-fb.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}
a.link-pinterest {
    background: url("../images/icon-pi.png") no-repeat 50%;
    background: url("../images/icon-pi.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}
a.link-instagram {
    background: url("../images/icon-in.png") no-repeat 50%;
    background: url("../images/icon-in.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}
a.link-youtube {
    background: url("../images/icon-yt.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}
a.link-facebook-pink {
    background: url("../images/icon-fb_pink.png") no-repeat 50%;
    background: url("../images/icon-fb_pink.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}
a.link-pinterest-pink {
    background: url("../images/icon-pi_pink.png") no-repeat 50%;
    background: url("../images/icon-pi_pink.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}
a.link-instagram-pink {
    background: url("../images/icon-in_pink.png") no-repeat 50%;
    background: url("../images/icon-in_pink.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}
a.link-youtube-pink {
    background: url("../images/icon-yt_pink.svg") no-repeat 50%, linear-gradient(transparent, transparent);
}