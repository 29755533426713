header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include fixed;
    overflow: hidden;
    z-index: 20;
    -webkit-transition: all 0.24s ease;
    -moz-transition: all 0.24s ease;
    -o-transition: all 0.24s ease;
    -ms-transition: all 0.24s ease;
    transition: all 0.24s ease;
    background: rgba(255,255,255,0);
}
header .header-container {
    position: relative;
    @include transition(all 0.24s ease);
    padding: 100px 100px 0;
}
header.mobile {
    padding-bottom: 30px;
    background: rgba(255,255,255,1);
    @include box-shadow(0, 4px, 6px, 0 rgba(0,0,0,0.1));

    .header-container {
        padding: 30px 40px 27px;

        #logo a {
            width: 124px;
            height: 32px;
            background-size: auto 32px;
        }

        #logo span {
            margin: 9px 0 0 26px;
        }

        #sitemap {
            float: right;
            margin: 1px 140px 0 0;
        }
    }

    // @media #{$tablet2} {
    //     padding-bottom: 40px;
    //     background: rgba(255,255,255,0.951);
    //     @include box-shadow(0, 4px, 7px, 0 rgba(0,0,0,0.147));
    // }
}

// body.touch header {
//     position: fixed;
// }
// body.touch header .header-container {
//     padding: 40px 40px 0;
// }

// body.touch #nav {
//     top: 37px;
//     right: 28px;
// }


#logo {
    float: left;
    @include transition(all 0.24s ease);
}
#logo a {
    width: 168px;
    height: 44px;
    float: left;
    text-indent: -9999px;
    font-size: 0;
    color: #fff;
    overflow: hidden;
    background: url("../images/logo-apex-arch.png") 0 50% no-repeat;
    background: url("../images/logo-apex-arch.svg") 0 50% no-repeat,linear-gradient(transparent,transparent);
}
#logo span {
    float: left;
    margin: 14px 0 0 26px;
    font-size: 14px;
    line-height: 1;
    @include transition(all 0.24s ease);
}
#sitemap {
    float: right;
    margin: 7px 100px 0 0;
     @include transition(all 0.24s ease);
}
#sitemap a {
    float: left;
    position: relative;
    top: 0;
    font-size: 20px;
    text-decoration: none;
    color: #888888;
    margin-left: 23px;
}

#sitemap a.active {
    padding-left: 27px;
    color: #21252b;
    cursor: default;
}
#sitemap a.active:first-child { margin-left: 0; padding-left: 0; }
#sitemap a.active:first-child:before { display: none; }
#sitemap a:nth-child(2) {
    max-width: 170px;
    white-space: nowrap;
    overflow:hidden !important;
    text-overflow: ellipsis;
}
#sitemap a.active:before {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    border-style: solid;
    border-width: 4.5px 0 4.5px 6px;
    border-color: transparent transparent transparent #cccccc;
}
#nav {
    top: 76px;
    right: 0;
    @include fixed;
    z-index: 1111;
    background: $white;
    @include transition(all 0.24s ease);

    @media #{$tablet1} {
        top: 0px;
    }

    &.mobile {
        top: 0;

        a .title-icon {
            padding-right: 12px;
            margin-right: 12px;
        }
    }
}
#nav a {
    width: 139px;
    height: 26px;
    float: left;
    padding: 32px 38px 32px 0;
    position: relative;
    -webkit-transition: all 0.24s ease;
    -moz-transition: all 0.24s ease;
    -o-transition: all 0.24s ease;
    -ms-transition: all 0.24s ease;
    transition: all 0.24s ease;
    color: $black;

    @media #{$mobil2} {
        width: auto;
        padding: 32px;
    }


    .title-icon {
        float: right;
        position: relative;
        font-size: 14px;
        line-height: 26px;
        padding-right: 20px;
        margin-right: 20px;
        @include transition(all 0.24s ease);

        @media #{$tablet1} {
            padding-right: 12px;
            margin-right: 12px;
        }

        @media #{$mobil2} {
            display: none;
        }

        &:before {
            width: 1px;
            height: 14px;
            @include pseudo;
            top: 50%;
            right: 0;
            margin-top: -7px;
            background: $grey;
        }
    }
}
#nav a span {
    width: 26px;
    height: 2px;
    display: block;
    float: right;
    position: relative;
    margin-top: 12px;
    background: $pink;
    -webkit-transition: all 0.24s ease;
    -moz-transition: all 0.24s ease;
    -o-transition: all 0.24s ease;
    -ms-transition: all 0.24s ease;
    transition: all 0.24s ease;
}
#nav a span:before,
#nav a span:after {
    width: 26px;
    height: 2px;
    content: "";
    position: absolute;
    left: 0;
    background: $pink;
    -webkit-transition: all 0.24s ease;
    -moz-transition: all 0.24s ease;
    -o-transition: all 0.24s ease;
    -ms-transition: all 0.24s ease;
    transition: all 0.24s ease;
}
#nav a span:before { top: -6px; }
#nav a span:after { top: 6px; }
/* active nav */
#nav a.active span {
    background: rgba(235, 29, 113, 0);
}
#nav a.active span:before,
#nav a.active span:after {
}
#nav a.active span:before,
#nav a.active span:after {
    top: 0;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}
#nav a.active span:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
#nav a.active span:after {
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

body.touch #nav a.active span {
    background: rgba(255,255,255,0) !important;
}

body.touch #nav a.active span:before,
body.touch #nav a.active span:after {
    top: 0 !important;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}
body.touch #nav a.active span:before {
    -webkit-transform: rotate(-45deg) !important;
    -ms-transform: rotate(-45deg) !important;
    transform: rotate(-45deg) !important;
}
body.touch #nav a.active span:after {
    -webkit-transform: rotate(-135deg) !important;
    -ms-transform: rotate(-135deg) !important;
    transform: rotate(-135deg) !important;
}


@media #{$hover} {
               #logo a:hover ~ span { margin: 14px 0 0 21px; }
            #nav a.active:hover span {
               background: $pink;
            }
        #nav a.active:hover span:before {
            top: -6px;
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        #nav a.active:hover span:after {
            top: 6px;
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
}

#home-sct .img-box.img1 .container-img.cnt1 img {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
}

.icon-top {
    width: 80px;
    height: 80px;
    @include fixed;
    bottom: 110px;
    right: 23px;
    z-index: 991;
    opacity: 0;
    visibility: hidden;
    @include transition(all 0.24s ease);
    background: url(../images/icon-top.svg) no-repeat 50%;

    &:hover {
        background-position: 50% 36%;
    }

    @media #{$tablet1} {
        display: none;
    }

    &.view {
        opacity: 1;
        visibility: visible;
    }
}
