#ft-buttons {
	width: 100%;
	float: left;
	margin-top: 100px;

	>a {
		width: 50%;
		height: 130px;
		float: left;
		display: flex;
		position: relative;
		text-decoration: none;
		color: $white;
		background: $pink;
		@include border-box;
		@include transition(all 0.24s ease);

		@media #{$mobil1} {
	        width: 100%;
	    }

		&:nth-of-type(2) {
			border-left: 1px solid rgba(234, 234, 234, 0.4);

			@media #{$mobil1} {
				border-left: 0;
		        border-top: 1px solid rgba(234, 234, 234, 0.4);
		    }
		}

		&:hover {
			span {
				&:before {
					bottom: 3px;
				}
			}
		}

		>span {
			margin: auto;
			display: inline-block;
			position: relative;
		    padding-bottom: 6px;
		    font-family: 'PT Serif', serif;
		    font-size: 22px;
		    color: $white;
		    cursor: pointer;

			&:before {
			    width: 100%;
			    height: 1px;
			    content: "";
			    position: absolute;
			    bottom: 0;
			    left: 0;
			    background: rgba(255, 255, 255,0.4);
			    @include transition(all 0.24s ease);
			}
		}
	}
}

#some-projects {
	width: 100%;
	float: left;
	margin-bottom: 100px;

	.sml-tl {
		width: 100%;
		float: left;
		text-align: center;
		font-family: $serif;
		font-size: 30px;
		letter-spacing: -0.45px;
	}

	>ul {
		margin-top: 90px;

		li {
			@media #{$tablet1} {
		        &:nth-of-type(3) {
		        	display: block;
		        }
		    }
		}
	}
}

section {
	ul.all {

		>li {
			@include border-box;
			@include transition(all 0.24s ease);

			&:not(.category):hover {
				z-index: 12;
				@include box-shadow(0, 20px, 40px, 0 rgba(0,0,0,0.25));

				span.long-arrow {
					width: 70px;
				}

				a.large-link {
					.row .content {
				    
					    &:before {
					    	opacity: 1;
					    }
					}


					.title {

						bottom: 0;

						h2 {
							color: $black!important;
						}

						.long-arrow {
							width: 55px;
						}
						
					}
				}
			}

			a.large-link {
			    display: block;
			    position: relative;
			    color: #fff;
			    text-decoration: none;

			    .row .content {
				    
				    &:before {
				    	@include absolute;
				    	@include pseudo;
				    	top: 0;
				    	left: 0;
				    	background: rgba(0,0,0,0.25);
				    	@include transition(all 0.24s ease);
				    	opacity: 0;
				    }
				}
			}
			a.large-link .title {
			    width: 100%;
			    height: 80px!important;
			    position: absolute;
			    top: auto!important;
			    bottom: -140px;
			    left: 0;
			    z-index: 10;
			    background: $white!important;
			    opacity: 1!important;
			    -webkit-transition: all 0.24s ease!important;
			    -moz-transition: all 0.24s ease!important;
			    -o-transition: all 0.24s ease!important;
			    -ms-transition: all 0.24s ease!important;
			    transition: all 0.24s ease!important;

			    .long-arrow {
			    	position: absolute;
			    	top: 50%;
			    	right: 50px;
			    	left: auto;
			    	margin-top: -4px;

			    	@media #{$desktop6} {
				        // margin-top: 13px;
				        right: 30px;
				    }
			    }
			}
			ul#project-list li.visible a.large-link .title,
			ul#project-list li.visible a.small-link,
			ul#project-list li:hover a.large-link .title,
			ul#project-list li:hover a.small-link {
			    opacity: 1;
			    filter: alpha(opacity=100);
			    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
			}
			a.large-link .title h2 { top: 0; }

			a.large-link .title h2 {
				max-width: 64%;
			    top: 50%!important;
			    font-size: 17px!important;
			    line-height: 24px!important;
		        padding: 40px 50px 0!important;
		        -webkit-line-clamp: 2!important;
			    position: absolute !important;
			    -webkit-transform: translateY(calc(-50% - 20px));
			    -moz-transform: translateY(calc(-50% - 20px));
			    transform: translateY(calc(-50% - 20px));
			}
		}
	}
}

