ul.home {

	>li {
		@include border-box;
		@include transition(all 0.24s ease);

		&:hover {
			z-index: 12;
			@include box-shadow(0, 20px, 40px, 0 rgba(0,0,0,0.25));

			span.long-arrow {
				width: 70px;
			}
		}

		&.box1,
		&.box2,
		&.box3,
		&.box4 {

			.content {
				&:before {
					display: none;
				}
			}
		}

		&.box2 {
			background-color: $black;

			article.hp-box {

				h2 {
					color: $white;
				}
			}
		}

		&.box3 {
			background-color: $pink;

			article.hp-box {

				h2 {
					color: $white;
				}

				p {
					color: $white;
					opacity: 0.7;
				}
			}
		}

		article.hp-box {
			position: absolute;
			top: 80px;
			left: 70px;
			right: 70px;
			bottom: 80px;

			@media #{$desktop7} {
	            top: 50px;
				bottom: 50px;
	        }

	        @media #{$desktop} {
	            top: 70px;
				left: 70px;
				right: 70px;
				bottom: 70px;
	        }

	        @media #{$tablet3} {
	            top: 40px;
				bottom: 40px;
	        }

	        @media #{$mobil2} {
	            top: 70px;
				left: 70px;
				right: 70px;
				bottom: 70px;
	        }

	        @media #{$mobil5} {
	            top: 40px;
				left: 40px;
				right: 40px;
				bottom: 40px;
	        }

			>h2 {
				font-family: $serif;
			    // font-size: 45px;
			    font-weight: normal;
			    line-height: 50px;
			    letter-spacing: -0.03em;
			    display: -webkit-box;
			    -webkit-line-clamp: 2;
			    -webkit-box-orient: vertical;
			    // overflow: hidden;
			    // max-height: 100px;
			    text-overflow: ellipsis;
			    color: $black;
			    @include transition(color .24s ease);

			    font-size: calc(0.87vw + 0.87vh + 2vmin);

			    @media #{$tablet3} {
			    	font-size: 35px;
			    }

			    @media #{$mobil2} {
			    	font-size: 45px;
			    }
			}

			>p {
				position: absolute;
				bottom: 45px;
				left: 0;
				color: #888;
				font-size: 14px;
				line-height: 24px;

				@media #{$desktop7} {
		            bottom: 20px;
		        }

		        @media #{$desktop} {
		            bottom: 45px;
		        }
			}
		}
	}
}

span.long-arrow {
	width: 55px;
	height: 8px;
	position: absolute;
	left: 0;
	bottom: 0;
	display:block;
	overflow: hidden;
	background-position: right center;
	background-repeat: no-repeat;
	background-image: url(../images/arr1.svg);
	-webkit-transition: width .29s ease;
	transition: width .29s ease;

	&.white {
		background-image: url(../images/arr2.svg);

		&:before {
			background-color: $white;
		}
	}

	&:before {
		height: 2px;
		@include pseudo;
		top: 50%;
		left: 0;
		right: 3px;
		margin-top: -1px;
		background-color: $pink;
	}

	&:hover {
		width: 70px;
	}
}