@charset "utf-8";
@import url(https://fonts.googleapis.com/css?family=PT+Serif|Roboto:300,400,700&subset=latin,latin-ext);
html,
body {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    font-size-adjust: none;
    -moz-font-size-adjust: none;
    -webkit-font-size-adjust: none;
}

body {
    font-family: 'Roboto', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #21252b;
    margin: 0;
    padding: 0;
    cursor: default;
    overflow-x: hidden;
    background-color: #fff;
}

* {
    margin: 0;
    padding: 0;
}

table,
p {
    padding: 0;
    margin: 0;
}

embed {
    padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'PT Serif', serif;
    font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
    margin: 0;
    font-size-adjust: none;
    -moz-font-size-adjust: none;
    -webkit-font-size-adjust: none;
}

a,
a:hover {
    cursor: pointer;
    outline: none;
}

a img {
    border: none;
}

button {
    width: auto;
    overflow: visible;
}

a:link,
section.articleWrapper aside:link {
    -webkit-tap-highlight-color: rgba(1, 1, 1, 0.563);
}

img {
    font-family: "Helvetica";
    font-size: 10px;
    color: #ddd;
}

label,
img {
    display: block;
    border: none;
    outline: none;
    color: #000;
}

textarea {
    overflow: auto;
    resize: none;
}

input,
select,
textarea {
    font-family: "Helvetica", sans-serif;
    font-size: 12px;
    color: #111;
    -webkit-border-radius: none;
    outline: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

ol,
ul,
li {
    list-style: none;
    outline: none;
}

form,
dl,
dd,
dt,
ul,
ol,
li {
    padding: 0;
    margin: 0;
}

canvas {
    overflow: hidden;
}

acronym {
    border: none;
    cursor: help;
}

iframe {
    overflow: hidden;
}

::selection {
    background: rgba(235, 29, 113, 1);
    color: #fff;
}

::-moz-selection {
    background: rgba(235, 29, 113, 1);
    color: #fff;
}

::-webkit-selection {
    background: rgba(235, 29, 113, 1);
    color: #fff;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
    display: block;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

@-ms-viewport {
    width: device-width;
}

img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    -moz-transition: background-color 5000s ease-in-out 0s;
    -ms-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}


/*
font-family: 'Roboto', sans-serif;
font-family: 'PT Serif', serif;
*/